<template src="./viewTask.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, addPhoneMask, getFormatedDate, getContractStatus, getFormatedAmount, convertToISOString } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { SelectModel } from '../../../models/GUIModels';
    import { LeadDetailed } from '../../../models/Task';

    interface ComponentModel {
        lead: LeadDetailed | null
        nextSteps: SelectModel[]
        sex: SelectModel[],
        clubs: SelectModel[],
        selectedSex: SelectModel,
        selectedStatus: SelectModel | null,
        selectedNextStep: SelectModel | null,
        selectedManagers: SelectModel | null, 
        selectedClub: SelectModel | null,
        date: string,
        name: string,
        mail: string,
        message: string,
        isButtonDisabled: boolean,
        nameErrorText: string,
        dateErrorText: string,
        photoUrl: string,
        currentContractIndex: number,
        isShowInfo: boolean,
        canPlayMp3: boolean,
        isLoading: boolean,
        historyWrapperClass: string
    }

    export default defineComponent({
        name: 'ViewTask',
        props: ["managers", "statuses", "leadId"],
        emits: ["close", "update", "openLead"], 
        data(): ComponentModel {
            return {
                lead: null,
                nextSteps: [
                    { id: "", text: "task.absent", isTranslated: true, groupName: null, disabled: false },
                    { id: "call", text: "crm_next_step.call", isTranslated: true, groupName: null, disabled: false },
                    { id: "email", text: "crm_next_step.email", isTranslated: true, groupName: null, disabled: false },
                    { id: "sms", text: "crm_next_step.sms", isTranslated: true, groupName: null, disabled: false }
                ],
                sex: [
                    { id: "false", text: 'task.view.female', isTranslated: true, disabled: false, groupName: null },
                    { id: "true", text: 'task.view.male', isTranslated: true, disabled: false, groupName: null }
                ],
                clubs: [],
                selectedSex: { id: "true", text: 'task.view.male', isTranslated: true, disabled: false, groupName: null },
                selectedStatus: null,
                selectedNextStep: null,
                selectedManagers: null,
                selectedClub: null,
                name: "",
                date: "",
                mail: "",
                message: "",
                isButtonDisabled: false,
                nameErrorText: "",
                dateErrorText: "",
                photoUrl: '/assets/img/person.svg',
                currentContractIndex: 0,
                isShowInfo: true,
                canPlayMp3: false,
                isLoading: false,
                historyWrapperClass: ""
            }
        },
        methods: {
            getLead: function () {
                this.isLoading = true;

                axiosRequests.get(editUrl(Routes.getLead, this.leadId))
                    .then(
                        (data: LeadDetailed) => {
                            this.lead = data;

                            if (data.client) this.photoUrl = data.client.photoUrl

                            this.name = data.firstName;
                            this.date = getFormatedDate(data.nextStepDate, "yyyy-MM-DDTHH:mm");
                            this.mail = data.email;

                            this.selectedStatus = this.statuses.find(x => x.id == data.leadStatus?.leadStatusId.toString()) ?? null;
                            this.selectedNextStep = this.nextSteps.find(x => x.id == data.nextStep.toString()) ?? null;
                            this.selectedManagers = this.managers.find(x => x.id == data.user?.userId.toString()) ?? null;

                            if (this.selectedManagers == null && data.user) this.selectedManagers = { id: data.user?.userId.toString(), text: data.user?.fullName.toString(), isTranslated: false, groupName: null, disabled: false };

                            this.clubs = this.lead.leadAvailableClubs.map(x => { return { id: x.clubId.toString(), text: x.clubName, isTranslated: false, disabled: !x.isEnabled, groupName: null } });

                            this.selectedClub = { id: this.lead.club?.clubId.toString(), text: this.lead.club?.name, disabled: false, groupName: null, isTranslated: false }

                            if (this.lead.client) {
                                if (this.lead.openingReason) {
                                    if (this.clubs.length > 1) {
                                        this.historyWrapperClass = "history_wrapper_client_with_opening_reason_and_club";
                                    } else {
                                        this.historyWrapperClass = "history_wrapper_client_with_opening_reason";
                                    }
                                } else {
                                    if (this.clubs.length > 1) {
                                        this.historyWrapperClass = "history_wrapper_client_with_club";
                                    } else {
                                        this.historyWrapperClass = "history_wrapper_client";
                                    }
                                }
                            } else {
                                if (this.lead.openingReason) {
                                    if (this.clubs.length > 1) {
                                        this.historyWrapperClass = "history_wrapper_without_client_with_opening_reason_and_club";
                                    } else {
                                        this.historyWrapperClass = "history_wrapper_without_client_with_opening_reason";
                                    }
                                } else {
                                    if (this.clubs.length > 1) {
                                        this.historyWrapperClass = "history_wrapper_without_client_with_club";

                                    } else {
                                        this.historyWrapperClass = "history_wrapper_without_client";
                                    }
                                }
                            }

                            this.isLoading = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoading = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            nextContact: function () {
                if (this.currentContractIndex + 1 != this.lead?.client?.contracts.length) this.currentContractIndex++;
            },
            previousContact: function () {
                if (this.currentContractIndex - 1 != -1) this.currentContractIndex--;
            },
            close: function () {
                this.$emit("close", false);
            },
            clickOnRedirect: function () {
                if (!this.lead?.client) return;

                this.$store.commit('SET_LAST_VIEWED_CLIENT', { fi: "", id: this.lead?.client.id, buttonText: 'back_button.crm', buttonPath: `/tasks/${this.lead?.leadId}`, isNeedTranslatedButtonText: true });

                this.$router.push(`/client/${this.lead?.client.id}`);
            },
            setNowTime: function(){
                this.date = getFormatedDate(convertToISOString(new Date()), "yyyy-MM-DDTHH:mm")

            },
            save: function () {
                let data = {};

                if (this.lead?.client) {
                    if (!this.date) this.dateErrorText = 'task.create.date_error_text';

                    if (this.dateErrorText) return;

                    data = {
                        clubId: this.selectedClub?.id ? this.selectedClub.id : null,
                        nextStepDate: getFormatedDate(this.date, "yyyy-MM-DDTHH:mm"),
                        description: this.message,
                        nextStep: this.selectedNextStep?.id ? this.selectedNextStep.id : null,
                        statusId: this.selectedStatus?.id ? this.selectedStatus.id : null,
                        managerId: this.selectedManagers?.id ? this.selectedManagers.id : null
                    };

                } else {
                    if (!this.name) this.nameErrorText = 'task.create.name_error_text';
                    if (!this.date) this.dateErrorText = 'task.create.date_error_text';

                    if (this.nameErrorText || this.dateErrorText) return;

                    data = {
                        clubId: this.selectedClub?.id ? this.selectedClub.id : null,
                        nextStepDate: getFormatedDate(this.date, "yyyy-MM-DDTHH:mm"),
                        description: this.message,
                        nextStep: this.selectedNextStep?.id ? this.selectedNextStep.id : null,
                        statusId: this.selectedStatus?.id ? this.selectedStatus.id : null,
                        firstName: this.name,
                        email: this.mail,
                        sex: this.selectedSex.id == "true",
                        managerId: this.selectedManagers?.id ? this.selectedManagers.id : null
                    };
                }

                this.isButtonDisabled = true;

                axiosRequests.patch(editUrl(Routes.updateLead, this.leadId), data)
                     .then(
                         () => {
                             this.$emit("close", true);            
                         }
                     )
                     .catch(
                         async (err: any) => {
                             this.isButtonDisabled = false;

                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                  this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         }
                     );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            },
            checkCanPlayMp3: function () {
                let obj = document.createElement("audio");
                return obj.canPlayType('audio/mpeg') == 'probably'
            },
            addPhoneMask,
            getFormatedDate,
            getContractStatus,
            getFormatedAmount
        },
        async beforeMount() {
            await this.getLead();
            this.canPlayMp3 = this.checkCanPlayMp3()
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .view_lead_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .view_lead_body {
        background-color: white;
        width: 1500px;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
        max-height: 98%;
        display: flex;
        flex-flow: column;
    }

    .view_lead_body_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px 8px 24px;
        display: flex;
    }

    .view_lead_content_wrapper {
        overflow: auto;
        padding-top: 16px;
        display: flex;
        width: 100%;
        flex-flow: wrap;
    }

    .view_lead {
        padding: 0px 12px 0px 24px;
        width: 50%;
        flex: 1;
        height: 100%;
    }

    .two_block_wrapper {
        width: 100%;
        display: flex;
    }

    .three_block_wrapper {
        width: 100%;
    }

    .left_block {
        display: flex;
        flex-flow: column;
        margin-right: 8px;
        width: calc(50% - 8px);
    }

    .right_block {
        display: flex;
        flex-flow: column;
        margin-left: 8px;
        width: calc(50% - 8px);
    }

    .left_two_blocks {
        display: flex;
        margin-right: 8px;
        width: calc(50% - 8px);
    }

    .right_one_block {
        display: flex;
        margin-left: 8px;
        width: calc(50% - 8px);
    }


    .view_lead_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .client_photo {
        background-size: cover;
        width: 35px;
        min-width: 35px;
        height: 35px;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
        background-color: white;
    }

    .client_title_wrapper {
        margin-left: 12px;
        display: flex;
        flex-flow: column;
        width: calc(100% - 45px);
    }

    .client_name {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2px;
        width: fit-content;
        max-width: 100%;
    }

    .client_phone {
        margin: 0px;
        color: #65656F;
        font-size: 12px;
    }

    .client_redirect{
        cursor: pointer;
        text-decoration: underline
    }

    .block_label {
        color: black;
        margin-top: 0px;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        font-weight: bold;
    }

    .block_info {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        color: #65656F;
    }

    .dot_active {
        background-color: green;
        margin-top: 5.5px;
    }

    .dot_disable {
        background-color: black;
        margin-top: 5.5px;
    }

    .in_club_status {
        margin: 0;
        white-space: nowrap;
    }


    .contract_wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        border: 1px solid #D5D5D5;
        border-radius: 24px;
        overflow: hidden;
        margin-bottom: 12px;
    }

    .contract_title {
        padding: 12px 18px 4px 18px;
        display: flex;
        background-color: #f6f7f8;
        justify-content: space-between;
        width: calc(100% - 36px);
    }

    .agreement {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
    }

    .contract {
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        margin: 0px;
        color: #65656F;
    }

    .contract_status {
        color: #65656F;
        margin-top: 4px;
        margin-left: 10px;
        font-size: 14px;
    }

    .contract_switch_button {
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 9px;
        height: 32px;
        width: 32px;
        justify-content: center;
    }

    .contract_switch_button_img {
        margin-top: 1px;
    }

    .contract_switch_button_disabled {
        opacity: 0.4;
    }

    .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-top: 4px;
        margin-right: 5px;
    }

    .contract_switch_text {
        margin: 7px 0px 0px 0px;
        letter-spacing: 2px;
    }

    .contract_info {
        padding: 8px 24px 0px 24px;
    }

    .view_lead_history {
        width: calc(50% - 24px);
        display: flex;
        flex-direction: column;
        border: 1px solid #D5D5D5;
        border-radius: 24px;
        overflow: hidden;
        margin-bottom: 18px;
        margin-right: 24px;
        flex: 1;
    }

    .view_lead_history_title {
        padding: 16px 24px;
        display: flex;
        background-color: #f6f7f8;
        font-size: 18px;
        font-weight: bold;
    }

    .client_info_wrapper {
        display: flex;
        flex-flow: wrap;
        padding-left: 4px;
    }

    .client_info {
        width: 33%;
    }

    .select_wrapper {
        border-radius: 12px;
        display: flex;
        border: 1px solid #D5D5D5;
        width: fit-content;
    }

    .select_button_active {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        color: white;
        width: 125px;
        margin: 0px;
    }

    .select_button {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: white;
        font-size: 16px;
        line-height: 24px;
        color: black;
        width: 125px;
        margin: 0px;
    }

    .select_block{
        display: none;
    }

    .history {
        margin: 0px 24px;
        border-bottom: 1px solid #D5D5D5;
        margin-top: 12px;
    }

    .history:last-child {
        border-bottom: unset;
    }

    .history_title {
        display: flex;
    }

    .history_dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-top: 5px;
        margin-right: 5px;
        background-color: #083C4D;
    }

    .history_date {
        color: #65656F;
        width: 140px;
    }

    .history_event_type {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 160px);
        margin-bottom: 12px;
    }

    .history_description {
        color: #65656F;
        padding: 0px 0px 12px 0px;
    }

    .history_wrapper_client_with_opening_reason {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 633px;
    }

    .history_wrapper_client_with_opening_reason_and_club {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 682px;
    }

    .history_wrapper_client_with_club {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 627px;
    }

    .history_wrapper_without_client_with_opening_reason {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 390px;
    }

    .history_wrapper_without_client_with_club {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 384px;
    }

    .history_wrapper_without_client_with_opening_reason_and_club {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 439px;
    }

    .history_wrapper_client {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 578px;
    }

    .history_wrapper_without_client {
        overflow: auto;
        display: flex;
        flex-flow: column;
        height: 339px;
    }

    .audio{
        height: 30px !important;
    }

    .download_audio {
        color: #007CA3;
    }

    .loading {
        text-align: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 36px;
        font-weight: bold;
    }

    .now_button {
        cursor: pointer;
        height: 19px;
        width: 19px;
        border-radius: 50%;
        background-color: white;
        border: gainsboro;
        position: absolute;
        right: 62px;
        top: 8px;
        padding: 0;
    }

    @media(max-width: 1550px) {
        .view_lead_body {
            width: 98%;
        }

        .client_info {
            width: 50%;
        }

        .history_wrapper_client_with_opening_reason {
            height: 688px;
        }

        .history_wrapper_client_with_club {
            height: 682px;
        }

        .history_wrapper_client_with_opening_reason_and_club {
            height: 737px;
        }

        .history_wrapper_without_client_with_opening_reason {
            height: 445px;
        }

        .history_wrapper_without_client_with_club {
            height: 439px;
        }

        .history_wrapper_without_client_with_opening_reason_and_club {
            height: 494px;
        }

        .history_wrapper_client {
            height: 633px;
        }

        .history_wrapper_without_client {
            height: 394px;
        }
    }

    @media(max-width: 1050px) {
        .client_info {
            width: 33%;
        }

        .select_block {
            display: block;
            width: 100%;
            margin-bottom: 12px;
            margin-left: 24px;
        }

        .view_lead {
            padding: 0px 24px;
            width: 100%;
        }

        .view_lead_history {
            margin-left: 24px;
            width: 100%;
            border: none;
            border-radius: 0px;
        }

        .left_two_blocks {
            margin-right: 0px;
            width: 100%;
        }

        .right_one_block {
            margin-left: 0px;
            width: 100%;
        }

        .view_lead_body {
            width: 98%;
        }

        .mobile_hide{
            display: none;
        }

        .view_lead_history_title{
            display: none;
        }

        .history {
            margin: 0px 4px;
            margin-top: 12px;
        }
    }

    @media(max-width: 768px) {
        .client_info {
            width: 50%;
        }

        .history_wrapper_client_with_opening_reason {
            height: 740px;
        }

        .history_wrapper_client_with_club {
            height: 734px;
        }

        .history_wrapper_client_with_opening_reason_and_club {
            height: 789px;
        }

        .history_wrapper_client {
            height: 687px;
        }
    }

    @media(max-width: 500px) {
        .client_info {
            width: 100%;
        }

        .two_block_wrapper {
            flex-flow: column;
        }

        .left_two_blocks {
            flex-flow: column;
        }

        .left_block {
            margin-right: 0px;
            width: 100%;
        }

        .right_block {
            margin-left: 0px;
            width: 100%;
        }


        .button_space {
            margin-left: 0px;
        }

        .view_lead_buttons_wrapper {
            display: block;
        }

        .agreement {
            font-size: 15px;
            line-height: 17px;
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
            width: unset;
        }

        .history_wrapper_client_with_opening_reason {
            height: auto;
        }

        .history_wrapper_client_with_club {
            height: auto;
        }

        .history_wrapper_client_with_opening_reason_and_club {
            height: auto;
        }

        .history_wrapper_without_client_with_opening_reason {
            height: auto;
        }

        .history_wrapper_without_client_with_opening_reason_and_club {
            height: auto;
        }

        .history_wrapper_without_client_with_club {
            height: auto;
        }

        .history_wrapper_client {
            height: auto;
        }

        .history_wrapper_without_client {
            height: auto;
        }

        .history_title {
            display: block;
        }

        .history_event_type {
            width: 100%;
            margin-top: 4px;
        }
    }
</style>