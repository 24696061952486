import moment from 'moment';
import { store } from '../store'
import { Routes } from '../const'
import { axiosRequests } from '../services/axiosinstance';
export const editUrl = function (url: string, param?: string | number, isLocal: boolean = false) {  
    if (isLocal) {
        url = process.env.VUE_APP_LOCAL_URL + url;
    } else {
        url = process.env.VUE_APP_API_URL + url;
    }
    
    if (param === undefined) return url

    return url.replace('{id}', param.toString())
}

export const getFormatedDate = function (date: string | null, format: string): string {
    if (!date) return "";

    moment.locale(navigator.language);

    //НЕ переводит дату и время в часовой пояс пользователя
    return moment.parseZone(date).format(format);

    //переводит дату и время в часовой пояс пользователя
    //return moment(date).format(format);
}

export const convertToISOString = function (date: Date): string {
    const offsetMinutes = date.getTimezoneOffset();
    return new Date(date.getTime() - offsetMinutes * 60000).toISOString();
}


export const getContractStatus = function (staus: number): string{
    if (staus == 0) {
        return "new";
    } else if (staus == 1) {
        return "active";
    } else if (staus == 2) {
        return "frozen";
    } else if (staus == 3) {
        return "indebt";
    } else if (staus == 4) {
        return "blocked";
    } else if (staus == 5) {
        return "finished";
    } else if (staus == 6) {
        return "terminated";
    } else if (staus == 10) {
        return "refunded";
    }

    return "";
}

export const getServiceContractStatus = function (staus: number): string {
    if (staus == 0) {
        return "new";
    } else if (staus == 1) {
        return "active";
    } else if (staus == 2) {
        return "frozen";
    } else if (staus == 3) {
        return "indebt";
    } else if (staus == 4) {
        return "blocked";
    } else if (staus == 5) {
        return "finished";
    } else if (staus == 6) {
        return "terminated";
    } else if (staus == 7) {
        return "done";
    } else if (staus == 8) {
        return "cancelled";
    } else if (staus == 10) {
        return "refunded";
    }

    return "";
}

export const getFreezeStatus = function (staus: number): string {
    if (staus == 0) {
        return "awaiting_activation";
    } else if (staus == 1) {
        return "active";
    } else if (staus == 2) {
        return "finished";
    } else if (staus == 3) {
        return "awaiting_finish";
    } else if (staus == 4) {
        return "cancelled";
    }

    return "";
}

export const getOrderStatus = function (staus: number): string {
    if (staus == 0) {
        return "not_processed";
    } else if (staus == 1) {
        return "processing";
    } else if (staus == -1) {
        return "cancelled";
    } else if (staus == 2) {
        return "paid";
    } else if (staus == 3) {
        return "not_paid";
    } else if (staus == 4) {
        return "refunded";
    }

    return "";
}

export const generateOrderStatus = function (processingStatus: number, paidStatus: number): number {
    if (processingStatus == 0) {
        return 0;
    } else if (processingStatus == 1) {
        return 1;
    } else if (processingStatus == -1) {
        return -1;
    } else if (processingStatus == 2 && paidStatus == 1) {
        return 2;
    } else if (processingStatus == 2 && paidStatus == 0) {
        return 3;
    } else if (processingStatus == 2 && paidStatus == -1) {
        return 4;
    }

    return 0;
}


export const getRefundOrderStatus = function (staus: number): string {
    if (staus == 0) {
        return "not_processed";
    } else if (staus == 1) {
        return "processing";
    } else if (staus == -1) {
        return "cancelled";
    } else if (staus == 2) {
        return "refunded";
    } else if (staus == 3) {
        return "not_refunded";
    }

    return "";
}

export const generateRefundOrderStatus = function (processingStatus: number, refundStatus: number): number {
    if (processingStatus == 0) {
        return 0;
    } else if (processingStatus == 1) {
        return 1;
    } else if (processingStatus == -1) {
        return -1;
    } else if (processingStatus == 2 && refundStatus == 1) {
        return 2;
    } else if (processingStatus == 2 && refundStatus == 0) {
        return 3;
    } 

    return 0;
}

export const getOrderPaymentsProcessingProvider = function (paymentsProcessingProvider: string): string {
    if (paymentsProcessingProvider == "russian_standard_bank") {
        return "order_payments_processing_provider.russian_standard_bank";
    } else if (paymentsProcessingProvider == "pos_terminal") {
        return "order_payments_processing_provider.pos";
        
    } else if (paymentsProcessingProvider == "cash") {
        return "order_payments_processing_provider.cash";
    }
    else if (paymentsProcessingProvider == "bank_transfer") {
        return "order_payments_processing_provider.bankTransfer";
    }

    return paymentsProcessingProvider;
}


export const getTrainingBlockStatus = function (staus: number): string {
    if (staus == 0) {
        return "not_paid";
    } else if (staus == 1) {
        return "active";
    } else if (staus == -1) {
        return "disactive";
    }

    return "";
}

export const getTrainingStatus = function (staus: number): string {
    if (staus == 0) {
        return "not_paid";
    } else if (staus == 1) {
        return "accept";
    } else if (staus == -1) {
        return "reject";
    }

    return "";
}

export const getMailingStatus = function (staus: number): string {
    if (staus == 0) {
        return "new";
    } else if (staus == 1) {
        return "awaiting_dispatch";
    } else if (staus == 2) {
        return "sending";
    } else if (staus == 3) {
        return "sent";
    } else if (staus == 4) {
        return "not_ready";
    } else if (staus == 5) {
        return "stopped";
    }

    return "";
}

export const addPhoneMask = function (phone: string): string {
    if (!phone || phone.length != 11) return phone;

    const phoneArr = [...phone];

    let result = "+";

    for (let i = 0; i < phoneArr.length; i++) {
        if (i == 1) {
            result += " (" + phoneArr[i];
        } else if (i == 3) {
            result += phoneArr[i] + ") ";
        } else if (i == 6) {
            result += phoneArr[i] + "-";
        } else if (i == 8) {
            result += phoneArr[i] + "-";
        } else {
            result += phoneArr[i];
        }  
    }

    return result;
}

export const getPermissions = async function (url: string): Promise<void> {
    if ((url == "clients" || url == "client") && store.getters.getClientsPermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "client")).then((data: string[]) => { store.commit('SET_CLIENTS_PERMISSION', data) }).catch(() => { });
        await axiosRequests.get(editUrl(Routes.getPermission, "contract")).then((data: string[]) => { store.commit('SET_CONTRACTS_PERMISSION', data) }).catch(() => { });
        await axiosRequests.get(editUrl(Routes.getPermission, "servicecontract")).then((data: string[]) => { store.commit('SET_SERVICE_CONTRACTS_PERMISSION', data) }).catch(() => { });
        await axiosRequests.get(editUrl(Routes.getPermission, "order")).then((data: string[]) => { store.commit('SET_ORDERS_PERMISSION', data) }).catch(() => { });
        await axiosRequests.get(editUrl(Routes.getPermission, "trainingblock")).then((data: string[]) => { store.commit('SET_TRAINING_BLOCKS_PERMISSION', data) }).catch(() => { });
    }

    if (url == "contract" && store.getters.getContractsPermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "contract")).then((data: string[]) => { store.commit('SET_CONTRACTS_PERMISSION', data) }).catch(() => { });
        await axiosRequests.get(editUrl(Routes.getPermission, "servicecontract")).then((data: string[]) => { store.commit('SET_SERVICE_CONTRACTS_PERMISSION', data) }).catch(() => { });
    }

    if (url == "order" && store.getters.getOrdersPermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "order")).then((data: string[]) => { store.commit('SET_ORDERS_PERMISSION', data) }).catch(() => { });
    }

    if (url == "refundorder" && store.getters.getRefundOrdersPermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "refundorder")).then((data: string[]) => { store.commit('SET_REFUND_ORDERS_PERMISSION', data) }).catch(() => { });
    }

    if ((url == "mailings" || url == "mailing")&& store.getters.getMailingsPermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "mailing")).then((data: string[]) => { store.commit('SET_MAILINGS_PERMISSION', data) }).catch(() => { });
    }

    if ((url == "tasks") && store.getters.getTasksPermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "lead")).then((data: string[]) => { store.commit('SET_TASKS_PERMISSION', data) }).catch(() => { });
    }

    if ((url == "timetable") && store.getters.getTimetablePermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "timetable")).then((data: string[]) => { store.commit('SET_TIMETABLES_PERMISSION', data) }).catch(() => { });
    }

    if ((url == "user") && store.getters.getUserPermission.length == 0) {
        await axiosRequests.get(editUrl(Routes.getPermission, "user")).then((data: string[]) => { store.commit('SET_USER_PERMISSION', data) }).catch(() => { });
    }
}

export const getFormatedAmount = function (amount: number): string {
    if (amount == 0) return "0";
    if (!amount) return "";

    if (amount % 100 == 0) {
        return (amount / 100).toLocaleString('ru-RU');
    } else {
        if (amount > 0) {
            return Math.floor(amount / 100).toLocaleString('ru-RU') + "," + amount.toString().slice(amount.toString().length - 2);
        } else {
            return Math.ceil(amount / 100).toLocaleString('ru-RU') + "," + amount.toString().slice(amount.toString().length - 2);
        }
    }
}


export const checkIfClickInsideAlert = function (target: any): boolean {
    if (target.className == "alert_wrapper") {
        return true;
    } else if (target.className != "alert_wrapper" && target.parentElement) {
        return checkIfClickInsideAlert(target.parentElement);
    }

    return false;
}

export const getTextColor = function (bgColor: string): string {
    const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? "#000" : "#fff"
}

export const getCardType = function (type: string): string {
    if (type == "VI" || type == "visa" || type == "VISA_INTERNATIONAL") return "Visa";
    if (type == "MC" || type == "mastercard" || type == "MASTERCARD_WORLDWIDE") return "MasterCard";
    if (type == "MR" || type == "mir" || type == "NSPK_MIR") return "Mir";

    return type;
}

export const getUrlPlural = function (url: string): string {
    switch (url) {
        case "/client":
            return "/clients"
        case "/training-class":
            return "/training-classes"
        case "/mailing":
            return "/mailings"
        default:
            return url;
    }
}

export const retryGetPermissions = async function (permission: string)
{
    let result: string[] = [];
    try {
        await axiosRequests.get(editUrl(Routes.getPermission, permission)).then((data: string[]) => { result = data; store.commit(getMutationByPermission(permission), data) });
        return result;
    }
    catch {
        return result;
    }
}

function getMutationByPermission(permission: string) {
    switch (permission) {
        case "client":
            return "SET_CLIENTS_PERMISSION"
        case "contract":
            return "SET_CONTRACTS_PERMISSION"
        case "servicecontract":
            return "SET_SERVICE_CONTRACTS_PERMISSION"
        case "order":
            return "SET_ORDERS_PERMISSION"
        case "refundorder":
            return "SET_REFUND_ORDERS_PERMISSION"
        case "trainingblock":
            return "SET_TRAINING_BLOCKS_PERMISSION"
        case "mailing":
            return "SET_MAILINGS_PERMISSION"
        case "lead":
            return "SET_MAILINGS_PERMISSION"
        case "timetable":
            return "SET_TIMETABLES_PERMISSION"
        case "user":
            return "SET_USER_PERMISSION"

        default:
            return "";
    }
}

