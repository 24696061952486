<template src="./search.html"></template>

<script lang="ts">
    import { axiosRequests } from '../../services/axiosinstance';
    import { defineComponent } from 'vue';
    import { editUrl } from '../../services/helper';
    import { Routes } from '../../const';
    import { TableColum } from '../../models/Table'
    import { Clients } from '../../models/Clients'

    interface ComponentModel {
        columTemplates: TableColum[],
        allData: any[],
        isLoad: boolean,
        isCtrPressed: boolean
    }

    export default defineComponent({
        name: 'SearchComponent',
        data(): ComponentModel {
            return {
                columTemplates: [],
                allData: [],
                isLoad: false,
                isCtrPressed: false
            }
        },
        methods: {
            find: async function(search: string){
                this.isLoad = true;

                await axiosRequests.get(editUrl(Routes.findClients + "?textToSearch=" + search))
                    .then(
                        (data: Clients[]) => {
                            this.columTemplates = [
                                { title: 'search.fi', type: 'text', isSortable: true, template: '%data%' },
                                { title: 'search.email', type: 'text', isSortable: true, template: '<div class="email_and_phone">%data%</div>' },
                                { title: 'search.phone', type: 'phone', isSortable: true, template: '<div class="email_and_phone">%data%</div>' },
                                { title: 'search.sex', type: 'sex', isSortable: true, template: '<div class="sex">%data%</div>' },
                                { title: 'search.agreement', type: 'text', isSortable: true, template: '<div class="agreement">%data%</div>' },
                                { title: 'search.contract', type: 'text', isSortable: true, template: '<div class="contract">%data%</div>' },
                                { title: 'search.start_date', type: 'date', isSortable: false, template: '<div class="date">%data%</div>' },
                                { title: 'search.status', type: 'contract_status', isSortable: false, template: '<div class="flex status_text_%color%"><div class="dot dot_contract_%color%"></div>%data%</div>' }
                            ];

                            this.allData = data.map(x => { return { id: x.id, data: [`${x.lastName} ${x.firstName}`, x.email, x.phone, x.sex.toString(), x.agreement, x.contract, new Date(x.startDate), x.status] } });

                            this.isLoad = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            openClient: function (id: string) {
                if (!id) return;

                if (this.isCtrPressed) {
                    this.$store.commit('SET_LAST_VIEWED_CLIENT', { fi: "", id: id, buttonText: "", buttonPath: null, isNeedTranslatedButtonText: false });

                    const index = this.allData.findIndex(x => x.id.toString() == id);
                    if (index != -1) this.$store.commit('UPDATE_LAST_VIEWED_CLIENT', { fi: this.allData[index].data[0], id: id });

                    window.open('/client/' + id, '_blank');
                } else {
                    this.$store.commit('SET_LAST_VIEWED_CLIENT', { fi: "", id: id, buttonText: 'back_button.search', buttonPath: `/search?find=${this.$route.query.find}`, isNeedTranslatedButtonText: true });

                    this.$router.push('/client/' + id);
                }
            },
            checkCtrPressed: function (e) {
                if (e.keyCode == 17) this.isCtrPressed = true;
            },
            checkCtrDroped: function (e) {
                if (e.keyCode == 17) this.isCtrPressed = false;
            }
        },
         watch: {
             '$route.query.find': {
                handler: async function (find) {
                    this.find(find)
                },
                deep: true,
                immediate: true
             }
         },
        async beforeMount() {
            window.addEventListener("keyup", this.checkCtrDroped);
            window.addEventListener("keydown", this.checkCtrPressed);
        },
        beforeUnmount: function () {
            window.removeEventListener("keyup", this.checkCtrDroped);
            window.removeEventListener("keydown", this.checkCtrPressed);
        }
    });
</script>

<style scoped>
    .search_wrapper {
        overflow: hidden;
        max-height: calc(100vh - 135px);
        min-height: 200px;
        display: flex;
        flex-flow: column;
    }

    .table_wrapper {
        overflow: auto;
        padding-right: 10px;
    }

    .total {
        color: #65656F;
        margin: 0;
        font-weight: 600;
    }

    .total_wrapper{
        display: flex;
        justify-content: space-between;
    }

    @media(max-width: 768px) {
        .search_wrapper {
            max-height: calc(100vh - 130px);
        }
    }

    @media(max-width: 500px) {
        .table_wrapper {
            padding-right: 5px;
        }
    }
</style>
