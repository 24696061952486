<template src="./viewClient.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, addPhoneMask, getFormatedDate, getContractStatus, getFormatedAmount, getServiceContractStatus, getCardType, generateOrderStatus, getOrderPaymentsProcessingProvider, generateRefundOrderStatus, retryGetPermissions, convertToISOString } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { Menu } from '../../../models/Menu';
    import { Client, ReferralClients } from '../../../models/Clients';
    import { Contract } from '../../../models/Contract';
    import { Comment } from '../../../models/Comment';
    import { Order, OrderStatus } from '../../../models/Order';
    import { ChangeBankCard } from '../../../models/ChangeBankCard';
    import { Training } from '../../../models/Training';
    import { RefundOrder } from '../../../models/RefundOrder';
    import { TrainingBlock } from '../../../models/TrainingBlock';
    import { ClientEvent } from '../../../models/ClientEvent';
    import { Attendance } from '../../../models/Attendance';
    import { Club } from '../../../models/Club';
    import { Pass } from '../../../models/Pass';
    import { SelectModel } from '../../../models/GUIModels';
    import ChangeContractStatus from './ChangeContractStatus/ChangeContractStatus.vue';
    import WriteMessageForClient from './WriteMessageForClient/WriteMessageForClient.vue';
    import AddRestriction from './AddRestriction/AddRestriction.vue';
    import ViewRestrictions from './ViewRestrictions/ViewRestrictions.vue';
    import UpdateClientSourse from './UpdateClientSourse/UpdateClientSourse.vue';
    import AddClientTrainingBlock from './AddClientTrainingBlock/AddClientTrainingBlock.vue';
    import RetryToPayTrainingBlock from './RetryToPayTrainingBlock/RetryToPayTrainingBlock.vue';
    import AddPhoto from '../../AddPhoto/AddPhoto.vue';
    import LinkPassCard from '../../PassCard/LinkPassCard/LinkPassCard.vue';
    import PassCardAlert from '../../PassCard/PassCardAlert/PassCardAlert.vue';
    import { TableColum, TableRow } from '../../../models/Table'

    interface ComponentModel {
        clientId: number,
        isEdit: boolean,
        tabs: Menu[],
        activeTab: Menu,
        currentClient: Client | null,
        tempLastName: string,
        tempFirstName: string,
        tempEmail: string,
        tempPhone: string,
        tempBirthday: string,
        tempSex: SelectModel | null,
        options: any,
        monthlyAttendanceChartOptions: any,
        yearAttendanceChartOptions: any,
        minBirthdayDate: string,
        maxBirthdayDate: string,
        sex: SelectModel[],
        lastnameErrorText: string,
        firstnameErrorText: string,
        emailErrorText: string,
        phoneErrorText: string,
        birthdayErrorText: string,
        clientBirthdayText: string,
        hasEditPermission: boolean,
        hasChangePasswordPermission: boolean,
        currentContractIndex: number,
        isShowEditMenu: boolean,
        hasChangeContractStatusPermission: boolean,
        hasCancelPlannedFreezePermission: boolean,
        hasCancelTerminationPermission: boolean,
        hasUploadPhotoPermission: boolean,
        showAlert: boolean,
        showChangeContractStatus: boolean,
        isEditSaveButtonDisabled: boolean,
        alertTitle: string,
        alertText: string,
        alertAction: string,
        showComments: boolean,
        editComment: boolean,
        newComment: string
        newCommentErrorText: string,
        isNewCommentSaveButtonDisabled: boolean,
        hasWriteMessagePermission: boolean,
        showWriteMessageForClient: boolean,
        mobileDevicesColumTemplates: TableColum[],
        contractsColumTemplates: TableColum[],
        paymentColumTemplates: TableColum[],
        refundColumTemplates: TableColum[],
        blockColumTemplates: TableColum[],
        trainingColumTemplates: TableColum[],
        invitationsColumTemplates: TableColum[],
        eventsColumTemplates: TableColum[],
        mobileDevicesTableData: TableRow[],
        contractsTableData: TableRow[],
        allPaymentTableData: TableRow[],
        paymentTableData: TableRow[],
        allRefundTableData: TableRow[],
        refundTableData: TableRow[],
        allTrainingsTableData: TableRow[],
        trainingTableData: TableRow[],
        trainingBlocksTableData: TableRow[],
        invitationsTableData: TableRow[],
        eventsTableData: TableRow[],
        isShowNewContractButton: boolean,
        isShowPayments: boolean,
        isShowRefunds: boolean,
        hasChangePaymentPermission: boolean,
        paymentFrameSrc: string,
        isShowPaymentFrame: boolean,
        availableContracts: SelectModel[],
        paymentStatus: SelectModel[],
        paymentProcessingProvider: SelectModel[],
        filterPaymentContract: SelectModel | null,
        filterPaymentStatus: SelectModel | null,
        filterPaymentProcessingProvider: SelectModel | null,
        filterPaymentDate: string | null,
        isShowPaymentFilters: boolean,
        refundStatus: SelectModel[],
        filterRefundContract: SelectModel | null,
        filterRefundStatus: SelectModel | null,
        filterRefundDate: string | null,
        isShowRefundFilters: boolean,
        hasAddClientBlockPermission: boolean,
        showAddClientTrainingBlock: boolean,
        trainings: SelectModel[],
        trainingTrainers: SelectModel[],
        trainingClubZones: SelectModel[],
        filterTraining: SelectModel | null,
        filterTrainingTrainer: SelectModel | null,
        filterTrainingClubZone: SelectModel | null,
        filterTrainingStartDate: string | null,
        isLoad: boolean,
        hasAddCommentPermission: boolean,
        hasDeleteCommentPermission: boolean,
        deletedAlertId: number,
        changeBankCardOrderId: number,
        showRetryToPayTrainingBlock: boolean,
        trainingBlockRetryToPayId: number,
        isCreateLeadDisabled: boolean,
        showAddPhoto: boolean,
        showlinkPassCard: boolean,
        abortRequest: AbortController | null,
        isShowAwaitingCard: boolean,
        passCardAlertTitle: string,
        hasConnection: boolean,
        showRegistrationPassAlert: boolean,
        alertRegistrationPassText: string,
        availableClubs: Club[],
        showPassButtonIn: boolean,
        showPassButtonOut: boolean,
        showClientSourses: boolean,
        hasSetRestriction: boolean,
        hasRemoveRestriction: boolean
        isShowAddRestriction: boolean,
        isShowViewRestrictions: boolean,
    }

    export default defineComponent({
        name: 'ViewClient',
        data(): ComponentModel {
            return {
                clientId: 0,
                options: {
                    mask: "+7 (###) ###-##-##"
                },
                monthlyAttendanceChartOptions: null,
                yearAttendanceChartOptions: null,
                minBirthdayDate: getFormatedDate(convertToISOString(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())), "yyyy-MM-DD"),
                maxBirthdayDate: getFormatedDate(convertToISOString(new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate())), "yyyy-MM-DD"),
                isEdit: false,
                tabs: [
                    { text: 'clients.view.profile', url: "profile", icon: "", subItems: [] },
                    { text: 'clients.view.contracts', url: "contracts", icon: "", subItems: [] },
                    { text: 'clients.view.payment_info', url: "payment-info", icon: "", subItems: [] },
                    { text: 'clients.view.trainings', url: "trainings", icon: "", subItems: [] },
                    { text: 'clients.view.training_blocks', url: "training-blocks", icon: "", subItems: [] },
                    { text: 'clients.view.invitations', url: "referral-program", icon: "", subItems: [] },
                    { text: 'clients.view.attendance', url: "attendance", icon: "", subItems: [] },
                    { text: 'clients.view.events', url: "events", icon: "", subItems: [] },
                    { text: 'clients.view.privacy_consents', url: "privacy-and-notifications", icon: "", subItems: [] },
                    { text: 'clients.view.mobile_devices', url: "mobile-devices", icon: "", subItems: [] }
                ],
                activeTab: { text: 'clients.view.profile', url: "profile", icon: "", subItems: [] },
                currentClient: null,
                tempLastName: "",
                tempFirstName: "",
                tempEmail: "",
                tempPhone: "",
                tempBirthday: "",
                tempSex: { id: "false", text: 'clients.female', isTranslated: true, disabled: false, groupName: null },
                sex: [
                    { id: "false", text: 'clients.female', isTranslated: true, disabled: false, groupName: null },
                    { id: "true", text: 'clients.male', isTranslated: true, disabled: false, groupName: null }
                ],
                lastnameErrorText: "",
                firstnameErrorText: "",
                emailErrorText: "",
                phoneErrorText: "",
                birthdayErrorText: "",
                clientBirthdayText: "",
                hasEditPermission: false,
                hasChangePasswordPermission: false,
                hasCancelPlannedFreezePermission: false,
                hasCancelTerminationPermission: false,
                hasUploadPhotoPermission: false,
                currentContractIndex: 0,
                isShowEditMenu: false,
                hasChangeContractStatusPermission: false,
                showAlert: false,
                showChangeContractStatus: false,
                isEditSaveButtonDisabled: false,
                alertTitle: "",
                alertText: "",
                alertAction: "",
                showComments: true,
                editComment: false,
                newComment: "",
                newCommentErrorText: "",
                isNewCommentSaveButtonDisabled: false,
                hasWriteMessagePermission: false,
                showWriteMessageForClient: false,
                mobileDevicesColumTemplates:  [
                    { title: 'clients.view.app_version', type: 'text', isSortable: true, template: '<div class="mobile_device_version">%data%</div>' },
                    { title: 'clients.view.phone_model', type: 'text', isSortable: true, template: '%data%' },
                    { title: 'clients.view.os_version', type: 'text', isSortable: true, template: '<div class="mobile_device_os">%data%</div>' },
                ],
                contractsColumTemplates:  [
                    { title: 'clients.view.contract', type: 'text', isSortable: true, template: '<div class="contract">%data%</div>' },
                    { title: 'clients.view.club', type: 'text', isSortable: false, template: '<div class="club">%data%</div>' },
                    { title: 'clients.view.start_date', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.end_date', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.freeze_price', type: 'text', isSortable: true, template: '<div class="contract_freeze_price">%data%</div>' },
                    { title: 'clients.view.status', type: 'contract_status', isSortable: false, template: '<div class="flex status_text_%color%"><div class="dot dot_table dot_contract_%color%"></div>%data%</div>' }
                ],
                paymentColumTemplates: [
                    { title: 'clients.view.order', type: 'text', isSortable: true, template: '<div class="order_title">%data%</div>' },
                    { title: 'clients.view.date', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.overdue', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.amount', type: 'text', isSortable: true, template: '<div class="">%data%</div>' },
                    { title: 'clients.view.contract', type: 'text', isSortable: true, template: '<div class="order_contract">%data%</div>' },
                    { title: 'clients.view.description', type: 'text', isSortable: true, template: '<div class="mobile_hide">%data%</div>' },
                    { title: 'clients.view.payments_processing_provider', type: 'payments_processing_provider', isSortable: true, template: '<div class="order_payments_processing_provider">%data%</div>' },
                    { title: 'clients.view.status', type: 'order_status', isSortable: false, template: '<div class="flex order_status_text_%color%"><div class="dot dot_table dot_order_%color%"></div>%data%</div>' }
                ],
                refundColumTemplates: [
                    { title: 'clients.view.order', type: 'text', isSortable: true, template: '<div class="order_title">%data%</div>' },
                    { title: 'clients.view.date', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.amount', type: 'text', isSortable: true, template: '<div class="">%data%</div>' },
                    { title: 'clients.view.contract', type: 'text', isSortable: true, template: '<div class="order_contract">%data%</div>' },
                    { title: 'clients.view.description', type: 'text', isSortable: true, template: '<div class="mobile_hide">%data%</div>' },
                    { title: 'clients.view.payments_processing_provider', type: 'payments_processing_provider', isSortable: true, template: '<div class="order_payments_processing_provider">%data%</div>' },
                    { title: 'clients.view.status', type: 'refund_order_status', isSortable: false, template: '<div class="flex refund_order_status_text_%color%"><div class="dot dot_table dot_refund_order_%color%"></div>%data%</div>' }
                ],
                blockColumTemplates: [
                    { title: 'clients.view.name', type: 'text', isSortable: true, template: '<div class="training_block_name">%data%</div>' },
                    { title: 'clients.view.price', type: 'text', isSortable: true, template: '<div class="">%data%</div>' },
                    { title: 'clients.view.used', type: 'text', isSortable: true, template: '<div class="training_block_used"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.group_programm', type: 'text', isSortable: true, template: '<div class="training_block_training_class">%data%</div>' },
                    { title: 'clients.view.trainer_name', type: 'text', isSortable: true, template: '<div class="training_block_trainer">%data%</div>' },
                    { title: 'clients.view.end_date', type: 'text', isSortable: true, template: '<div class="date mb-0"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.block_paid', type: 'text', isSortable: true, template: '<div class="">%data%</div>' },
                    { title: 'clients.view.block_active', type: 'training_block_status', isSortable: false, template: '<div class="dot dot_table dot_training_block_%color%"></div>' },
                ],
                trainingColumTemplates: [
                    { title: 'clients.view.training', type: 'text', isSortable: true, template: '<div class="training_title">%data%</div>' },
                    { title: 'clients.view.start_date', type: 'datetime', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.end_date', type: 'datetime', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.trainer', type: 'text', isSortable: true, template: '<div class="training_trainer">%data%</div>' },
                    { title: 'clients.view.club_zone', type: 'text', isSortable: true, template: '<div class="training_zone">%data%</div>' },
                    { title: 'clients.view.training_paid', type: 'bool', isSortable: true, template: '<div class="mobile_hide">%data%</div>' },
                ],
                invitationsColumTemplates: [
                    { title: 'clients.view.activation_date', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.fi', type: 'text', isSortable: true, template: '<div class="">%data%</div>' },
                    { title: 'clients.view.email', type: 'text', isSortable: true, template: '<div class="email_and_phone">%data%</div>' },
                    { title: 'clients.view.phone', type: 'phone', isSortable: true, template: '<div class="email_and_phone">%data%</div>' },
                    { title: 'clients.view.contract', type: 'text', isSortable: true, template: '<div class="refferal_client_contract">%data%</div>' },
                    { title: 'clients.view.status', type: 'contract_status', isSortable: false, template: '<div class="flex status_text_%color%"><div class="dot dot_contract_%color%"></div>%data%</div>' }
                ],
                eventsColumTemplates: [
                    { title: 'clients.view.date', type: 'datetime', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'clients.view.user_name', type: 'text', isSortable: true, template: '<div class="event_user">%data%</div>' },
                    { title: 'clients.view.event_type', type: 'text', isSortable: true, template: '<div class="event_type">%data%</div>' },
                    { title: 'clients.view.comment', type: 'text', isSortable: true, template: '<div class="">%data%</div>' },
                ],
                mobileDevicesTableData: [],
                contractsTableData: [],
                allPaymentTableData: [],
                paymentTableData: [],
                allRefundTableData: [],
                refundTableData: [],
                allTrainingsTableData: [],
                trainingTableData: [],
                trainingBlocksTableData: [],
                invitationsTableData: [],
                eventsTableData: [],
                isShowNewContractButton: false,
                isShowPayments: true,
                isShowRefunds: false,
                hasChangePaymentPermission: false,
                paymentFrameSrc: "",
                isShowPaymentFrame: false,
                availableContracts: [],
                paymentStatus: [
                    { id: "2", text: 'order_status.paid', isTranslated: true, disabled: false, groupName: null },
                    { id: "0", text: 'order_status.not_processed', isTranslated: true, disabled: false, groupName: null },
                    { id: "3", text: 'order_status.not_paid', isTranslated: true, disabled: false, groupName: null },
                    { id: "-1", text: 'order_status.cancelled', isTranslated: true, disabled: false, groupName: null },
                    { id: "1", text: 'order_status.processing', isTranslated: true, disabled: false, groupName: null },
                    { id: "4", text: 'order_status.refunded', isTranslated: true, disabled: false, groupName: null }
                ],
                paymentProcessingProvider: [
                    { id: 'order_payments_processing_provider.russian_standard_bank', text: 'order_payments_processing_provider.russian_standard_bank', isTranslated: true, disabled: false, groupName: null },
                    { id: 'order_payments_processing_provider.cash', text: 'order_payments_processing_provider.cash', isTranslated: true, disabled: false, groupName: null },
                    { id: 'order_payments_processing_provider.pos', text: 'order_payments_processing_provider.pos', isTranslated: true, disabled: false, groupName: null },
                ],
                filterPaymentContract: null,
                filterPaymentStatus: null,
                filterPaymentProcessingProvider: null,
                filterPaymentDate: null,
                refundStatus: [
                    { id: "2", text: 'refund_order_status.refunded', isTranslated: true, disabled: false, groupName: null },
                    { id: "0", text: 'refund_order_status.not_processed', isTranslated: true, disabled: false, groupName: null },
                    { id: "3", text: 'refund_order_status.not_refunded', isTranslated: true, disabled: false, groupName: null },
                    { id: "-1", text: 'refund_order_status.cancelled', isTranslated: true, disabled: false, groupName: null },
                    { id: "1", text: 'refund_order_status.processing', isTranslated: true, disabled: false, groupName: null },
                ],
                isShowPaymentFilters: false,
                filterRefundContract: null,
                filterRefundStatus: null,
                filterRefundDate: null,
                isShowRefundFilters: false,
                hasAddClientBlockPermission: false,
                showAddClientTrainingBlock: false,
                trainings: [],
                trainingTrainers: [],
                trainingClubZones: [],
                filterTraining: null,
                filterTrainingTrainer: null,
                filterTrainingClubZone: null,
                filterTrainingStartDate: null,
                isLoad: false,
                hasAddCommentPermission: false,
                hasDeleteCommentPermission: false,
                deletedAlertId: 0,
                changeBankCardOrderId: 0,
                showRetryToPayTrainingBlock: false,
                trainingBlockRetryToPayId: 0,
                isCreateLeadDisabled: false,
                showAddPhoto: false,
                showlinkPassCard: false,
                abortRequest: null,
                isShowAwaitingCard: false,
                passCardAlertTitle: "",
                hasConnection: false,
                showRegistrationPassAlert: false,
                alertRegistrationPassText: "",
                availableClubs: [],
                showPassButtonIn: false,
                showPassButtonOut: false,
                showClientSourses: false,
                hasSetRestriction: false,
                hasRemoveRestriction: false,
                isShowAddRestriction: false,
                isShowViewRestrictions: false
            }
        },
        methods: {
            getAvailableClubs: async function () {
                 await axiosRequests.get(editUrl(Routes.getClubs))
                     .then(
                         (data: Club[]) => {
                             this.availableClubs = data;
                         }
                     )
                     .catch(
                         async (err: any) => {
                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         }
                     );
             },
            getAttendence: async function(){
                if(this.monthlyAttendanceChartOptions && this.yearAttendanceChartOptions) return;

                await axiosRequests.get(editUrl(Routes.getClientAttendances, this.clientId))
                    .then(
                        (data: Attendance[]) => {
                            if (data.length != 2) return;

                            this.monthlyAttendanceChartOptions = this.fillChartOptions(this.$t('clients.view.monthly_attendance_title'), data[0].abscissaPoints, this.$t('clients.view.monthly_attendance_yAxis_tittle'), data[0].ordinatePoints, this.$t('clients.view.min'));
                            this.yearAttendanceChartOptions = this.fillChartOptions(this.$t('clients.view.year_attendance_title'), data[1].abscissaPoints, this.$t('clients.view.year_attendance_yAxis_tittle'), data[1].ordinatePoints, "");
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            fillChartOptions: function(tittle, xAxis, yAxis_tittle, series, measurement) {
                return {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: tittle
                    },
                    xAxis: {
                        categories: xAxis,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: yAxis_tittle
                        }
                    },
                    tooltip: {
                        useHTML: true,
                        formatter: function(){
                            const funcThis : any = this;

                            let s = '<div><b>' + funcThis.x + '</b></div>';
                            for(let point of funcThis.points){
                                s += '<div>' + point.series.name + ': <b>' + point.y + '</b> ' + measurement + '</div>';
                            }

                            return '<div>' + s + '</div>';
                        },
                        shared: true
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal'
                        }
                    },
                    accessibility: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: series
                }

            },
            getClient: async function () {
                this.isLoad = true;

                await axiosRequests.get(editUrl(Routes.getClient, this.clientId))
                    .then(
                        async (data: Client) => {
                            this.currentClient = data;
                            this.mobileDevicesTableData = data.mobileDevices.map(x => { return { id: "", data: [x.appVersion, x.model, x.os ]}});
                            this.contractsTableData = data.contracts.map(x => { return { id: x.id.toString(), data: [x.contract, x.clubName, x.startDate, x.endDate, getFormatedAmount(x.freezePrice) + " " + x.currencySign, x.status ]}});

                            this.currentClient.contracts = data.contracts.map(x => {
                                return {
                                    id: x.id,
                                    agreement: x.agreement,
                                    agreementId: x.agreementId,
                                    clientId: x.clientId,
                                    contract: x.contract,
                                    startDate: x.startDate,
                                    endDate: x.endDate,
                                    previousPaymentQueueDate: x.previousPaymentQueueDate,
                                    previousPaymentDueDate: x.previousPaymentDueDate,
                                    previousPaymentTotal: x.previousPaymentTotal,
                                    nextPaymentQueueDate: x.nextPaymentQueueDate,
                                    nextPaymentDueDate: x.nextPaymentDueDate,
                                    nextPaymentTotal: x.nextPaymentTotal,
                                    currencySign: x.currencySign,
                                    referralPromocode: x.referralPromocode,
                                    status: x.status,
                                    pin: x.pin,
                                    availableStatuses: x.availableStatuses,
                                    minimumFreezeDays: x.minimumFreezeDays,
                                    availableFreezingDaysNumber: x.availableFreezingDaysNumber,
                                    services: x.services,
                                    freezePrice: x.freezePrice,
                                    availableGuestVisit: x.availableGuestVisit,
                                    periodsPaid: x.periodsPaid,
                                    lastFailedPaymentReason: x.lastFailedPaymentReason,
                                    terminationDate: x.terminationDate,
                                    lastBlockedReason: x.lastBlockedReason,
                                    freeze: x.freeze,
                                    terminationReasons: x.terminationReasons,
                                    hasUserAgreedWithRecurringPayments: x.hasUserAgreedWithRecurringPayments,
                                    additionalFreezingDays: x.additionalFreezingDays,
                                    discounts: x.discounts,
                                    freezeHistories: x.freezeHistories,
                                    price: x.price,
                                    availableServices: x.availableServices,
                                    availableAdditionalFreezingDays: x.availableAdditionalFreezingDays,
                                    clientPaymentMean: x.clientPaymentMean,
                                    clubId: x.clubId,
                                    clubName: x.clubName,
                                    clientName: x.clientName,
                                    contractStatusDescription: this.getContractStatusDescription(x),
                                    isShowContractStatusCancelButton: this.checkNeedToShowContractStatusCancelButton(x),
                                    isShowPreviousPaymentQueueDate: x.previousPaymentQueueDate  != x.previousPaymentDueDate,
                                    isShowNextPaymentDueDate: x.nextPaymentQueueDate != x.nextPaymentDueDate
                                }
                            });

                            this.availableContracts = data.contracts.map(x => { return { id: x.contract, text: x.contract, isTranslated: false, disabled: false, groupName: null } });

                            this.$store.commit('UPDATE_LAST_VIEWED_CLIENT', { fi: `${data.lastName} ${data.firstName}`, id: this.clientId });

                            if (this.availableClubs.length == 0) await this.getAvailableClubs();


                            if (this.$store.getters.getSelectedClubId == 0 && this.availableClubs.length == 1) {
                                this.$store.commit('SET_SELECTED_CLUB_ID', this.availableClubs[0].id);
                            }

                            this.checkIfNeedToShowPassButton(this.$store.getters.getSelectedClubId);

                            this.isLoad = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                return this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            openEdit() {
                this.isShowEditMenu = false;
                this.tempLastName = this.currentClient?.lastName ?? "";
                this.tempFirstName = this.currentClient?.firstName ?? "";
                this.tempEmail = this.currentClient?.email ?? "";
                this.tempPhone = this.currentClient?.phone ?? "";
                this.tempBirthday = getFormatedDate(this.currentClient?.birthday ?? "", "yyyy-MM-DD");
                this.tempSex = this.currentClient?.sex ? { id: "true", text: 'clients.male', isTranslated: true, disabled: false, groupName: null } : { id: "false", text: 'clients.female', isTranslated: true, disabled: false, groupName: null };

                this.lastnameErrorText = "";
                this.firstnameErrorText = "";
                this.emailErrorText = "";
                this.phoneErrorText = "";
                this.birthdayErrorText = "";

                this.isEdit = true;
            },
            updateClientData: function () {
                this.tempPhone = this.tempPhone.replace(/\D/g, '');

                if (!this.tempLastName) this.lastnameErrorText = 'clients.view.lastname_error';
                if (!this.tempFirstName) this.firstnameErrorText = 'clients.view.firstname_error';
                if (!this.tempEmail || !this.validEmail(this.tempEmail)) this.emailErrorText = 'clients.view.email_error';
                if (!this.tempPhone || this.tempPhone.length != 11) this.phoneErrorText = 'clients.view.phone_error';
                if (!this.tempBirthday) this.birthdayErrorText = 'clients.view.birthday_error';

                if (this.lastnameErrorText || this.firstnameErrorText || this.emailErrorText || this.phoneErrorText || this.birthdayErrorText) return;

                this.isEditSaveButtonDisabled = true;
                
                const data = { firstName: this.tempFirstName, lastName: this.tempLastName, sex: this.tempSex?.id == "true", email: this.tempEmail, phone: this.tempPhone.substring(1, 11), phoneCountryCode: this.tempPhone.substring(0, 1), birthday: getFormatedDate(this.tempBirthday, "yyyy-MM-DD")};

                axiosRequests.patch(editUrl(Routes.updateClient, this.clientId), data)
                    .then(
                        (data: Client) => {
                            this.isEditSaveButtonDisabled = false;

                            if(!this.currentClient) return;

                            this.currentClient.lastName = data.lastName;
                            this.currentClient.firstName = data.firstName;
                            this.currentClient.email = data.email;
                            this.currentClient.phone = data.phone;
                            this.currentClient.birthday = data.birthday;
                            this.currentClient.sex = data.sex;

                            this.isEdit = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isEditSaveButtonDisabled = false;
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            validEmail: function (email: string) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            applySuccessAlertFunction: function(){
                this.showAlert = false;
                if (this.alertAction == "resetPassword") {
                    this.resetPassword();
                } else if (this.alertAction == "cancelFreeze") {
                    this.cancelFreeze();
                } else if (this.alertAction == "cancelTermination") {
                    this.cancelTermination();
                } else if (this.alertAction == "changePaymentMean") {
                    this.changePaymentMean();
                } else if (this.alertAction == "deleteComment") {
                    this.deleteComment();
                }
            },
            showResetPasswordAlert: function(){
                this.isShowEditMenu = false;
                this.alertTitle = 'clients.view.alert_reset_password_title';
                this.alertText = 'clients.view.are_you_sure';
                this.alertAction = "resetPassword";
                this.showAlert = true;
            },
            showChangePaymentMean: function () {
                this.isShowEditMenu = false;
                this.alertTitle = 'clients.view.alert_change_bank_card';
                this.alertText = 'clients.view.are_you_sure';
                this.alertAction = "changePaymentMean";
                this.showAlert = true;
            },
            showDeleteCommentdAlert: function(id: number){
                this.deletedAlertId = id;
                this.isShowEditMenu = false;
                this.alertTitle = 'clients.view.alert_delete_comment_title';
                this.alertText = 'clients.view.are_you_sure';
                this.alertAction = "deleteComment";
                this.showAlert = true;
            },
            resetPassword: function () {
                axiosRequests.post(editUrl(Routes.resetClientPassword, this.clientId))
                    .then(
                        () => {
                            this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'success_messages.client_password_has_been_reset');
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            clickOnTab: function () {
                this.$router.push(`/client/${this.clientId}/${this.activeTab.url}`);

                if (this.activeTab.url === 'attendance') {
                   this.getAttendence();
                   return;
                }

                if (this.activeTab.url === 'payment-info') {
                    this.getOrders();
                    this.getRefundOrders();
                    return;
                }

                if (this.activeTab.url === 'trainings') {
                    this.getTrainings();
                    return;
                }

                if (this.activeTab.url === 'training-blocks') {
                    this.getTrainingBlocks();
                    return;
                }

                if (this.activeTab.url === 'events') {
                    this.getClientEvents();
                    return;
                }

                if (this.activeTab.url === 'referral-program') {
                    this.getReferralMembers();
                    return;
                }
            },
            nextContact: function () {
                if (this.currentContractIndex + 1 != this.currentClient?.contracts.length) this.currentContractIndex++;
            },
            previousContact: function () {
                if (this.currentContractIndex - 1 != -1) this.currentContractIndex--;
            },
            onClickOutsideEditMenu: function () {
                this.isShowEditMenu = false;
            },
            getContractStatusDescription: function(contract: Contract) {
                if (contract.status == 1 && contract.freeze?.status == 0) {
                    return `${this.$t('clients.view.contract_status_description_awaiting_freeze')} ${getFormatedDate(contract.freeze?.startDate, "DD.MM.yyyy")}`;
                } else if (contract.status == 2 && contract.freeze?.status == 3) {
                    return `${this.$t('clients.view.contract_status_description_awaiting_fininished_freeze')} ${getFormatedDate(contract.freeze?.endDate, "DD.MM.yyyy") }`;
                } else if (contract.status == 2 && contract.freeze?.status == 1) {
                    return this.$t('clients.view.contract_status_description_in_freeze', [getFormatedDate(contract.freeze?.startDate, "DD.MM.yyyy"), getFormatedDate(contract.freeze?.endDate, "DD.MM.yyyy")]);
                } else if (contract.status != 5 && contract.status != 6 && contract.terminationDate) {
                    return `${this.$t('clients.view.contract_status_description_terminate')} ${getFormatedDate(contract.terminationDate, "DD.MM.yyyy") }`;
                } else if (contract.status == 0 && contract.periodsPaid> 0 && new Date(contract.startDate).toDateString() == new Date().toDateString()) {
                    return this.$t('clients.view.contract_status_description_activating');
                } else if (contract.status == 0 && contract.periodsPaid > 0 && new Date(contract.startDate).toDateString() != new Date().toDateString()) {
                    return `${this.$t('clients.view.contract_status_description_awaiting_activation')} ${getFormatedDate(contract.startDate, "DD.MM.yyyy") }`;
                } else if (contract.status == 4 && contract.lastBlockedReason) {
                    return contract.lastBlockedReason;
                } else if (contract.status == 3 && contract.lastFailedPaymentReason) {
                    return this.$t(contract.lastFailedPaymentReason);
                }
            },
            checkNeedToShowContractStatusCancelButton: function (contract: Contract) {
                if (contract.status == 1 && contract.freeze?.status == 0 && this.hasCancelPlannedFreezePermission) {
                    return true;
                } else if (contract.status != 5 && contract.status != 6 && contract.terminationDate && this.hasCancelTerminationPermission) {
                    return true;
                }else{
                    return false;
                }
            },
            getOrders: function(){
                if(this.allPaymentTableData.length > 0) return;

                 axiosRequests.get(editUrl(Routes.getOrders, this.clientId))
                     .then(
                         (data: Order[]) => {

                             this.allPaymentTableData = data.map(x => { return { id: x.orderId.toString(), data: [x.orderId.toString().padStart(8, '0'), new Date(getFormatedDate(x.queueDate, 'yyyy.MM.DD')), x.queueDate != x.dueDate ? new Date(getFormatedDate(x.dueDate, 'yyyy.MM.DD')) : "", `${getFormatedAmount(x.total)} ${x.currencySign}`, x.contractName, x.comment, getOrderPaymentsProcessingProvider(x.bankProcessingProviderName), generateOrderStatus(x.processingStatus, x.paidStatus)]}});

                             this.paymentTableData = this.allPaymentTableData;
                         }
                     )
                     .catch(
                         async (err: any) => {
                             this.isLoad = false;

                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         }
                     );

            },
            getRefundOrders: function(){
                 if(this.allRefundTableData.length > 0) return;

                  axiosRequests.get(editUrl(Routes.getRefundOrders, this.clientId))
                      .then(
                          (data: RefundOrder[]) => {
                              this.allRefundTableData = data.map(x => { return { id: x.refundOrderId.toString(), data: [x.refundOrderId.toString().padStart(8, '0'), new Date(getFormatedDate(x.dueDate, 'yyyy.MM.DD')), `${getFormatedAmount(x.total)} ${x.currencySign}`, x.contractName, x.comment, getOrderPaymentsProcessingProvider(x.bankProcessingProviderName), generateRefundOrderStatus(x.processingStatus, x.refundStatus)]}});

                            this.refundTableData = this.allRefundTableData;
                          }
                      )
                      .catch(
                          async (err: any) => {
                              this.isLoad = false;

                              if (err.status == 401) {
                                  return;
                              } else if (err.status == 403) {
                                  this.$router.push('/forbidden');
                              } else if (err.status == 400 && err.data) {
                                  this.$store.commit('SET_ADD_ERROR', err.data);
                              } else {
                                  this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                              }
                          }
                  );
            },
            clearPaymentAllFilters: function () {
                this.filterPaymentContract = null;
                this.filterPaymentStatus = null;
                this.filterPaymentProcessingProvider = null;
                this.filterPaymentDate = null;
                this.applyPaymentFilter();
            },
            applyPaymentFilter: function () {
                this.paymentTableData = this.allPaymentTableData.filter(x => {
                    if (this.filterPaymentDate == null && this.filterPaymentContract == null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider == null) {
                        return true;
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract == null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider == null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString()) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate == null && this.filterPaymentContract != null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider == null) {
                        if (x.data[4] == this.filterPaymentContract?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate == null && this.filterPaymentContract == null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider == null) {
                        if (x.data[7] == this.filterPaymentStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract != null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider == null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString() && x.data[4] == this.filterPaymentContract?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate == null && this.filterPaymentContract != null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider == null) {
                        if (x.data[4] == this.filterPaymentContract?.id && x.data[7] == this.filterPaymentStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract == null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider == null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString() && x.data[7] == this.filterPaymentStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract != null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider == null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString() && x.data[4] == this.filterPaymentContract?.id && x.data[7] == this.filterPaymentStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate == null && this.filterPaymentContract == null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[6] == this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract == null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString() && x.data[6] ==  this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate == null && this.filterPaymentContract != null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[4] == this.filterPaymentContract?.id && x.data[6] ==  this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate == null && this.filterPaymentContract == null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[7] == this.filterPaymentStatus?.id && x.data[6] ==  this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract != null && this.filterPaymentStatus == null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString() && x.data[4] == this.filterPaymentContract?.id && x.data[6] ==  this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate == null && this.filterPaymentContract != null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[4] == this.filterPaymentContract?.id && x.data[7] == this.filterPaymentStatus?.id && x.data[6] ==  this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract == null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString() && x.data[7] == this.filterPaymentStatus?.id && x.data[6] ==  this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterPaymentDate != null && this.filterPaymentContract != null && this.filterPaymentStatus != null && this.filterPaymentProcessingProvider != null) {
                        if (x.data[1].toDateString() == new Date(this.filterPaymentDate).toDateString() && x.data[4] == this.filterPaymentContract?.id && x.data[7] == this.filterPaymentStatus?.id && x.data[6] ==  this.filterPaymentProcessingProvider.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }

                });
            },
            clearRefundAllFilters: function () {
                this.filterRefundDate = null;
                this.filterRefundContract = null;
                this.filterRefundStatus = null;
                this.applyRefundFilter();
            },
            applyRefundFilter: function () {
               this.refundTableData = this.allRefundTableData.filter(x => {
                    if (this.filterRefundDate == null && this.filterRefundContract == null && this.filterRefundStatus == null) {
                        return true;
                    } else if (this.filterRefundDate != null && this.filterRefundContract == null && this.filterRefundStatus == null) {
                        if (x.data[1].toDateString() == new Date(this.filterRefundDate).toDateString()) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterRefundDate == null && this.filterRefundContract != null && this.filterRefundStatus == null) {
                        if (x.data[3] == this.filterRefundContract?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterRefundDate == null && this.filterRefundContract == null && this.filterRefundStatus != null) {
                        if (x.data[6] == this.filterRefundStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterRefundDate != null && this.filterRefundContract != null && this.filterRefundStatus == null) {
                        if (x.data[1].toDateString() == new Date(this.filterRefundDate).toDateString() && x.data[3] == this.filterRefundContract?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterRefundDate == null && this.filterRefundContract != null && this.filterRefundStatus != null) {
                        if (x.data[3] == this.filterRefundContract?.id && x.data[6] == this.filterRefundStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterRefundDate != null && this.filterRefundContract == null && this.filterRefundStatus != null) {
                        if (x.data[1].toDateString() == new Date(this.filterRefundDate).toDateString() && x.data[6] == this.filterRefundStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterRefundDate != null && this.filterRefundContract != null && this.filterRefundStatus != null) {
                        if (x.data[1].toDateString() == new Date(this.filterRefundDate).toDateString() && x.data[3] == this.filterRefundContract?.id && x.data[6] == this.filterRefundStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }

                });
            },
            getTrainings: function(){
                if(this.allTrainingsTableData.length > 0) return;

                 axiosRequests.get(editUrl(Routes.getClientTrainings, this.clientId))
                     .then(
                         (data: Training[]) => {
                             let allATrainings: Set<string> = new Set();
                             let allTrainers: Set<string> = new Set();
                             let allClubZones: Set<string> = new Set();

                             for (let training of data) {
                                 this.allTrainingsTableData.push({ id: "", data: [training.trainingClassName ? training.trainingClassName : this.$t('clients.view.personal_training'), new Date(training.beginDate) ,new Date(training.endDate), training.trainerNickName, training.clubZoneName, training.isPaid.toString()]})

                                 allATrainings.add(training.trainingClassName ? training.trainingClassName : this.$t('clients.view.personal_training'));
                                 allTrainers.add(training.trainerNickName);
                                 allClubZones.add(training.clubZoneName);
                             }

                             this.trainings = [...allATrainings].map(x => { return { id: x, text: x, disabled: false, isTranslated: false, groupName: null }});
                             this.trainingTrainers = [...allTrainers].map(x => { return { id: x, text: x, isTranslated: false, disabled: false, groupName: null }});
                             this.trainingClubZones = [...allClubZones].map(x => { return { id: x, text: x, isTranslated: false, disabled: false, groupName: null }});

                            this.trainingTableData = this.allTrainingsTableData;
                         }
                     )
                     .catch(
                         async (err: any) => {
                             this.isLoad = false;

                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         }
                     );

            },
            getTrainingBlocks: function () {
                if (this.trainingBlocksTableData.length > 0) return;

                axiosRequests.get(editUrl(Routes.getClientsTrainingBlocks, this.clientId))
                    .then(
                        (data: TrainingBlock[]) => {
                            this.trainingBlocksTableData = data.map(x => { return { id: "", data: [x.serviceName, `${getFormatedAmount(x.price)} ${x.currencySign}`, `${x.trainingsUsed}/${x.trainingsCount}`, x.trainingClassName, x.trainerName, x.endDate ? getFormatedDate(x.endDate, "DD.MM.yyyy") : this.$t('clients.view.infinite'), this.getTrainingBlockPaidString(x), this.getTrainingBlockActive(x) ] } });
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );

            },
            getTrainingBlockPaidString: function (trainingBlock: TrainingBlock) {
                if (trainingBlock.isPaid) {
                    return `<div class="client_block_paid"><span class="hide_in_desktop">${this.$t("contract.view.paid") }:</span>${this.$t("contract.view.yes")}<div>`;
                } else {
                    return `<button class="retry_to_pay_button" param-action="retryToPayTrainingBlock" param-id="${trainingBlock.clientTrainingBlockId}">${this.$t("contract.view.pay")}</button>`;
                }
            },
            getTrainingBlockActive: function (trainingBlock: TrainingBlock) {
                if (!trainingBlock.isPaid) {
                    return 0;
                } else if(trainingBlock.isActive && trainingBlock.isPaid) {
                    return 1;
                }else{
                    return -1;
                }
            },
            getClientEvents: function () {
                if (this.eventsTableData.length > 0) return;

                axiosRequests.get(editUrl(Routes.getClientEvents, this.clientId))
                    .then(
                        (data: ClientEvent[]) => {
                            this.eventsTableData = data.map(x => { return { id: "", data: [new Date(x.createDate), x.userName, this.$t(x.eventType), x.comments] } });
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );

            },
            getReferralMembers : function () {
                if (this.invitationsTableData.length > 0) return;

                axiosRequests.get(editUrl(Routes.getReferralMembers, this.clientId))
                    .then(
                        (data: ReferralClients[]) => {
                            this.invitationsTableData = data.map(x => { return { id: "", data: [new Date(x.contractCreateDate), `<a class="refferal_client" href='/client/${x.clientId}/profile' param-type='referral-client' param-forward-clientId='${x.clientId}' param-clientId='${this.currentClient?.id}' param-clientName='${this.currentClient?.lastName} ${this.currentClient?.firstName}' param-href='/client/${x.clientId}/profile'>${x.lastName} ${x.firstName}</a>`, x.email, x.phone, x.contractName, x.contractStatus] } });
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );

            },
            clearTrainingAllFilters: function () {
                this.filterTraining = null;
                this.filterTrainingTrainer = null;
                this.filterTrainingClubZone = null;
                this.filterTrainingStartDate = null;
                this.applyTrainingFilter();
            },
            applyTrainingFilter: function () {
                this.trainingTableData = this.allTrainingsTableData.filter(x => {
                    if (this.filterTrainingStartDate == null && this.filterTraining == null && this.filterTrainingTrainer == null && this.filterTrainingClubZone == null) {
                        return true;
                    } else if (this.filterTrainingStartDate != null && this.filterTraining == null && this.filterTrainingTrainer == null && this.filterTrainingClubZone == null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString()) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate == null && this.filterTraining != null && this.filterTrainingTrainer == null && this.filterTrainingClubZone == null) {
                        if (x.data[0] == this.filterTraining?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate == null && this.filterTraining == null && this.filterTrainingTrainer != null && this.filterTrainingClubZone == null) {
                        if (x.data[3] == this.filterTrainingTrainer?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate != null && this.filterTraining != null && this.filterTrainingTrainer == null && this.filterTrainingClubZone == null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString() && x.data[0] == this.filterTraining?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate == null && this.filterTraining != null && this.filterTrainingTrainer != null && this.filterTrainingClubZone == null) {
                        if (x.data[0] == this.filterTraining?.id && x.data[3] == this.filterTrainingTrainer?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate != null && this.filterTraining == null && this.filterTrainingTrainer != null && this.filterTrainingClubZone == null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString() && x.data[3] == this.filterTrainingTrainer?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate != null && this.filterTraining != null && this.filterTrainingTrainer != null && this.filterTrainingClubZone == null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString() && x.data[0] == this.filterTraining?.id && x.data[3] == this.filterTrainingTrainer?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate == null && this.filterTraining == null && this.filterTrainingTrainer == null && this.filterTrainingClubZone != null) {
                        if (x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate != null && this.filterTraining == null && this.filterTrainingTrainer == null && this.filterTrainingClubZone != null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString() && x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate == null && this.filterTraining != null && this.filterTrainingTrainer == null && this.filterTrainingClubZone != null) {
                        if (x.data[0] == this.filterTraining?.id && x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate == null && this.filterTraining == null && this.filterTrainingTrainer != null && this.filterTrainingClubZone != null) {
                        if (x.data[3] == this.filterTrainingTrainer?.id && x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate != null && this.filterTraining != null && this.filterTrainingTrainer == null && this.filterTrainingClubZone != null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString() && x.data[0] == this.filterTraining?.id && x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate == null && this.filterTraining != null && this.filterTrainingTrainer != null && this.filterTrainingClubZone != null) {
                        if (x.data[0] == this.filterTraining?.id && x.data[3] == this.filterTrainingTrainer?.id && x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate != null && this.filterTraining == null && this.filterTrainingTrainer != null && this.filterTrainingClubZone != null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString() && x.data[3] == this.filterTrainingTrainer?.id && x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterTrainingStartDate != null && this.filterTraining != null && this.filterTrainingTrainer != null && this.filterTrainingClubZone != null) {
                        if (x.data[1].toDateString() == new Date(this.filterTrainingStartDate).toDateString() && x.data[0] == this.filterTraining?.id && x.data[3] == this.filterTrainingTrainer?.id && x.data[4] == this.filterTrainingClubZone?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }

                });
            },
            showResetCancelStatusAlert: function(contract: Contract){
                if (contract.status == 1 && contract.freeze?.status == 0) {
                    this.alertTitle = 'clients.view.alert_cancel_freeze';
                    this.alertAction = "cancelFreeze";
                } else if (contract.status != 5 && contract.status != 6 && contract.terminationDate) {
                    this.alertTitle = 'clients.view.alert_cancel_termination';
                    this.alertAction = "cancelTermination";
                } else {
                    this.alertTitle = "";
                    this.alertText = "";
                    this.alertAction = "";
                    this.showAlert = false;

                    return;
                }

                this.alertText = 'clients.view.are_you_sure';
                this.showAlert = true;
            },
            changePaymentMean: function () {
                axiosRequests.post(editUrl(Routes.changeBankCard, this.clientId))
                    .then(
                        (data: ChangeBankCard) => {
                            this.paymentFrameSrc = data.url;
                            this.changeBankCardOrderId = data.orderId;

                            this.isShowPaymentFrame = true;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            cancelFreeze: function () {
                axiosRequests.post(editUrl(Routes.cancelPlannedFreeze, this.currentClient?.contracts[this.currentContractIndex].id))
                    .then(
                        () => {
                            this.getClient();
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            cancelTermination: function(){
                axiosRequests.post(editUrl(Routes.cancelTermination, this.currentClient?.contracts[this.currentContractIndex].id))
                    .then(
                        () => {
                            this.getClient();
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            openContract: function (id: string) {
                if (!id) return;

                this.$router.push('/contract/' + id);
            },
            openOrder: function (id: string) {
                if (!id) return;

                this.$router.push('/order/' + id);
            },
            openRefundOrder: function (id: string) {
                if (!id) return;

                this.$router.push('/refundorder/' + id);
            },
            closeFrame: function () {
                this.isShowPaymentFrame = false;
                this.paymentFrameSrc = "";
                this.changeBankCardOrderId = 0;
                this.allPaymentTableData = [];
                this.getClient();
                this.getOrders();
            },
            getFrameMessage: async function (message: any) {
                if (message.origin != process.env.VUE_APP_WORKPLACE_OLD_URL || message.data != "close") return;

                await this.getOrderStatus();

                this.closeFrame()
            },
            getOrderStatus: async function () {
                await axiosRequests.get(editUrl(Routes.getOrderStatus, this.changeBankCardOrderId))
                    .then(
                        (data: OrderStatus) => {
                            if (data.isOrderSuccessfullyPaid) return;

                            if (data.resultDescription) {
                                this.$store.commit('SET_ADD_ERROR', data.resultDescription);
                            } else {
                                this.$store.commit('SET_ADD_SUCCESS_MESSAGES', "success_messages.new_bank_card_is_being_checked");
                            }      
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            createComment: function(){
                 if (!this.newComment) this.newCommentErrorText = 'clients.view.new_comment_error';

                 if (this.newCommentErrorText) return;

                 this.isNewCommentSaveButtonDisabled = true;

                axiosRequests.post(editUrl(Routes.createComment, this.clientId), { text: this.newComment})
                    .then(
                        (data: Comment) => {
                            this.currentClient?.comments.unshift(data);
                            this.isNewCommentSaveButtonDisabled = false;
                            this.editComment = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isNewCommentSaveButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            deleteComment: function(){
                if(this.deletedAlertId == 0) return;

                axiosRequests.delete(editUrl(Routes.deleteComment, this.deletedAlertId))
                    .then(
                        () => {
                            let index = this.currentClient?.comments.findIndex(x => x.id == this.deletedAlertId);
                            if(!index || index == -1){
                                this.getClient();
                                return;
                            }

                            this.currentClient?.comments.splice(index, 1);

                            this.deletedAlertId = 0;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            redirectToLastViewedClient: function (clientId: number, isDelete: boolean = false) {
                if (clientId != this.clientId) {
                    this.activeTab = { text: 'clients.view.profile', url: "profile", icon: "", subItems: [] };

                    if(!isDelete) this.$store.commit('UNSHIFT_LAST_VIEWED_CLIENT', clientId); 

                    this.$router.push('/client/' + clientId);
                }
            },
            deleteLastViewedClient: function (index: number) {
                if (index == 0) {
                    if (this.lastViewedClients.length == 1) this.$router.push('/clients');
                    else this.redirectToLastViewedClient(this.lastViewedClients[1].id, true);
                }

                this.$store.commit('SET_REMOVE_LAST_VIEWED_CLIENT', index);
            },
            closeChangeContractStausWindow: function (isNeedToUpdateClient: boolean) {
                this.showChangeContractStatus = false;

                if (isNeedToUpdateClient) this.getClient();
            },
            closeAddClientTrainingBlockWindow: function(isNeedToUpdateBlocks: boolean){
                this.showAddClientTrainingBlock = false;
                
                if (isNeedToUpdateBlocks) {
                    this.trainingBlocksTableData = [];
                    this.getTrainingBlocks();
                }  
            },
            updateTrainingBlocks: function(){
                this.showRetryToPayTrainingBlock = false;
                this.trainingBlocksTableData = [];
                this.getTrainingBlocks()
            },
            clickOnTableButton: function(id: number, action: string){
                 if (action == "retryToPayTrainingBlock") {
                     this.trainingBlockRetryToPayId = id;
                     this.showRetryToPayTrainingBlock = true;
                 }
            },
            createOrRedirectToLead: function () {
                if (this.currentClient?.hasLead) {
                    this.$router.push(`/tasks/${this.currentClient?.leadId}`);
                } else {
                    this.isCreateLeadDisabled = true;

                    axiosRequests.post(editUrl(Routes.createClientLead, this.clientId))
                        .then(
                            (data: any) => {
                                this.isCreateLeadDisabled = false;
                                this.$router.push(`/tasks/${data.leadId}`)
                            }
                        )
                        .catch(
                            async (err: any) => {
                                this.isCreateLeadDisabled = true;

                                if (err.status == 401) {
                                    return;
                                } else if (err.status == 500) {
                                    this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                                } else if (err.status == 403) {
                                    this.$router.push('/forbidden');
                                } else if (err.status == 400 && err.data) {
                                    return this.$store.commit('SET_ADD_ERROR', err.data);
                                } else {
                                    this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                                }
                            }
                        );
                }
            },
            closeAddPhotoWindow: function (isNeedToUpdateClient: boolean) {
                this.showAddPhoto = false;

                if (isNeedToUpdateClient) this.getClient();
            },
            createPassCard: function (clubId: number) {
                this.showlinkPassCard = false;

                if(!clubId) return;

                this.passCardAlertTitle = "pass_card.pass_card_alert.linking_card";
                this.isShowAwaitingCard = true;

                this.abortRequest = new AbortController();

                axiosRequests.post(editUrl(Routes.createPassCardLocal, undefined, true), { clubId: clubId, clientId: this.clientId, userId: null }, this.abortRequest.signal)
                     .then(
                         () => {
                             this.isShowAwaitingCard = false;

                             if (this.currentClient) this.currentClient.hasPassCard = true;

                             this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'success_messages.pass_card_linked');
                         }
                     )
                     .catch(
                         (err: any) => {
                             this.isShowAwaitingCard = false;

                             if (!err?.status) return;

                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         })

            },
            closeAwaitingCard: function () {
                this.isShowAwaitingCard = false;
                this.abortRequest?.abort();

                axiosRequests.post(editUrl(Routes.cancelCurrentOperation, undefined, true)).then(() => { }).catch(() => { })
            },
            closeAddRestriction: function (isNeedToUpdateClient: boolean) {
                this.isShowAddRestriction = false;

                if (isNeedToUpdateClient) this.getClient();
            },
            closeViewRestrictions: function (isNeedToUpdateClient: boolean) {
                this.isShowViewRestrictions = false;

                if (isNeedToUpdateClient) this.getClient();
            },
            deactivatePassCard: function () {
                this.isShowAwaitingCard = true;

                this.passCardAlertTitle = "pass_card.pass_card_alert.untie_card";
                
                this.abortRequest = new AbortController();

                axiosRequests.patch(editUrl(Routes.deactivatePassCardLocal, undefined, true), { clientId: this.clientId, userId: null }, this.abortRequest.signal)
                    .then(
                        () => {
                            this.isShowAwaitingCard = false;

                            if (this.currentClient) this.currentClient.hasPassCard = false;

                            this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'success_messages.pass_card_ublinked');
                        }
                    )
                    .catch(
                        (err: any) => {
                            this.isShowAwaitingCard = false;

                            if (!err?.status) return;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {

                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        })
            },
            checkIfNeedToShowPassButton: function (id: number) {
                let index = this.availableClubs.findIndex(x => x.id == id);

                if(index == -1) {
                    this.showPassButtonIn = false;
                    this.showPassButtonOut = false;

                    return;
                }

                if(!this.currentClient?.isInClub && this.availableClubs[index].virtualSkud.hasIn){
                     this.showPassButtonIn = true;
                     this.showPassButtonOut = false;

                    return;
                }

                if(this.currentClient?.isInClub && this.availableClubs[index].virtualSkud.hasOut){
                     this.showPassButtonIn = false;
                     this.showPassButtonOut = true;

                    return;
                }

                this.showPassButtonIn = false;
                this.showPassButtonOut = false;
            },
            showRegistrationPass: function() {

                 let index = this.availableClubs.findIndex(x => x.id == this.$store.getters.getSelectedClubId);

                 if(index == -1) return;

                if(this.currentClient?.isInClub){
                     this.alertRegistrationPassText =  `${this.$t("clients.view.confirm_sign_out")} <strong>${this.availableClubs[index].name}</strong>`;
                }else{
                    this.alertRegistrationPassText =  `${this.$t("clients.view.confirm_sign_in")} <strong>${this.availableClubs[index].name}</strong>`;
                }

                this.showRegistrationPassAlert = true;
            },
            registerPass: function() {

                this.showRegistrationPassAlert = false;

                axiosRequests.post(editUrl(Routes.registerPass, this.clientId), { isDirectionIn: !this.currentClient?.isInClub })
                     .then(
                         (data: Pass) => {
                             if(data.hasAccessGranted){
                                 this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'success_messages.passage_is_fixed');

                                 setTimeout(() => this.getClient(), 200);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', data.message);
                             }
                         }
                     )
                     .catch(
                         async (err: any) => {
                            if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         }
                     );

            },
            closeUpdateClientSourse: function (isNeedToUpdateClient: boolean) {
                this.showClientSourses = false;

                if (isNeedToUpdateClient) this.getClient();
            },
            clickOnBackButton: function () {
                if(!this.lastViewedClients[0].buttonPath) return;

                if(this.lastViewedClients[0].buttonPath == "-1") {
                    if(this.$router.options?.history?.state?.back == null) return;
                     this.$router.go(-1);
                     return;
                }

                this.$router.push(this.lastViewedClients[0].buttonPath);
            },
            getFormatedDate,
            addPhoneMask,
            getContractStatus,
            getFormatedAmount,
            getServiceContractStatus,
            getCardType
        },
        components: { changeContractStatus: ChangeContractStatus, writeMessageForClient: WriteMessageForClient, addClientTrainingBlock: AddClientTrainingBlock, retryToPayTrainingBlock: RetryToPayTrainingBlock, addPhoto: AddPhoto, linkPassCard: LinkPassCard, passCardAlert: PassCardAlert, updateClientSourse: UpdateClientSourse, addRestriction: AddRestriction, viewRestrictions: ViewRestrictions },
        watch: {
            '$route.params.id': {
                handler: async function (id) {
                    if (!id) return;

                     if (this.$route.params.tab == "profile") {
                         this.activeTab = { text: 'clients.view.profile', url: "profile", icon: "", subItems: [] };
                     } else if (this.$route.params.tab == "contracts") {
                         this.activeTab = { text: 'clients.view.contracts', url: "contracts", icon: "", subItems: [] };
                     } else if (this.$route.params.tab == "payment-info") {
                         this.activeTab = { text: 'clients.view.payment_info', url: "payment-info", icon: "", subItems: [] };
                     }  else if (this.$route.params.tab == "trainings") {
                         this.activeTab = { text: 'clients.view.trainings', url: "trainings", icon: "", subItems: [] };
                     }else if (this.$route.params.tab == "training-blocks") {
                         this.activeTab = { text: 'clients.view.training_blocks', url: "training-blocks", icon: "", subItems: [] };
                     } else if (this.$route.params.tab == "referral-program") {
                         this.activeTab = { text: 'clients.view.invitations', url: "referral-program", icon: "", subItems: [] };
                     } else if (this.$route.params.tab == "attendance") {
                         this.activeTab = { text: 'clients.view.attendance', url: "attendance", icon: "", subItems: [] };
                     } else if (this.$route.params.tab == "events") {
                         this.activeTab = { text: 'clients.view.events', url: "events", icon: "", subItems: [] };
                     } else if (this.$route.params.tab == "privacy-and-notifications") {
                         this.activeTab = { text: 'clients.view.privacy_consents', url: "privacy-and-notifications", icon: "", subItems: [] };
                     } else if (this.$route.params.tab == "mobile-devices") {
                         this.activeTab = { text: 'clients.view.mobile_devices', url: "mobile-devices", icon: "", subItems: [] };
                     } 

                    if (this.clientId != id) {
                         this.clientId = id;

                         await this.getClient();

                         this.isEdit = false;
                         this.editComment = false;

                         this.monthlyAttendanceChartOptions = null;
                         this.yearAttendanceChartOptions = null;

                         this.allPaymentTableData = [];
                         this.allRefundTableData = [];
                         this.allTrainingsTableData = [];
                         this.trainingBlocksTableData = [];
                         this.eventsTableData = [];
                         this.invitationsTableData = [];

                         this.paymentTableData = [];
                         this.refundTableData = [];

                         this.trainings = [];
                         this.trainingTrainers = [];
                         this.trainingClubZones = [];

                         this.trainingTableData = [];
                    }

                    if (this.activeTab.url === 'attendance') {
                        this.getAttendence();
                        return;
                    }

                    if (this.activeTab.url === 'payment-info') {
                         this.getOrders();
                         this.getRefundOrders();
                         return;
                    }

                    if (this.activeTab.url === 'trainings') {
                         this.getTrainings();
                         return;
                    }

                    if (this.activeTab.url === 'training-blocks') {
                        this.getTrainingBlocks();
                        return;
                    }

                    if (this.activeTab.url === 'events') {
                        this.getClientEvents();
                        return;
                    }

                    if (this.activeTab.url === 'referral-program') {
                        this.getReferralMembers();
                        return;
                    }
                },
                deep: true,
                immediate: true
            },
            '$store.getters.getSelectedClubId': {
                handler: async function (id) {
                    this.checkIfNeedToShowPassButton(id);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            lastViewedClients: function () {
                return this.$store.getters.getLastViewedClients;
            }
        },
        async beforeMount() {
            let clientsPermission = this.$store.getters.getClientsPermission;
            let contractsPermission = this.$store.getters.getContractsPermission;
            let trainingBlocksPermission = this.$store.getters.getTrainingBlocksPermission;

            if (clientsPermission.length == 0) clientsPermission = await retryGetPermissions("client");
            if (contractsPermission.length == 0) contractsPermission = await retryGetPermissions("contract");
            if (trainingBlocksPermission.length == 0) trainingBlocksPermission = await retryGetPermissions("trainingBlock");

            if (!clientsPermission?.includes('clients/{id}/detailed')) this.$router.push('/forbidden');

            this.hasEditPermission = clientsPermission.includes('clients/{id}/update');
            this.hasAddCommentPermission = clientsPermission.includes('clients/{id}/comments/create');
            this.hasDeleteCommentPermission = clientsPermission.includes('clients/comments/{id}/delete');
            this.hasChangeContractStatusPermission = contractsPermission.includes('contracts/{id}/change-status');
            this.hasChangePasswordPermission = clientsPermission.includes('clients/{id}/reset-password');
            this.hasCancelPlannedFreezePermission = contractsPermission.includes('contracts/{id}/cancel-planned-freeze');
            this.hasCancelTerminationPermission = contractsPermission.includes('contracts/{id}/cancel-termination');
            this.isShowNewContractButton = contractsPermission.includes('contracts/create');
            this.hasChangePaymentPermission = clientsPermission.includes('clients/{id}/change-bank-card');
            this.hasWriteMessagePermission = clientsPermission.includes('clients/{id}/send-message');
            this.hasAddClientBlockPermission = trainingBlocksPermission.includes('training-blocks/trainer/buy') || trainingBlocksPermission.includes('training-blocks/club/buy');
            this.hasUploadPhotoPermission = clientsPermission.includes('clients/{id}/upload-photo');

            this.hasSetRestriction = clientsPermission.includes('clients/{id}/restrictions/create');
            this.hasRemoveRestriction = clientsPermission.includes('clients/{id}/restrictions/deleteall');

            this.hasConnection = this.$store.getters.getHasConnectionToLocal; 
        },
        mounted() {
            window.addEventListener('message', this.getFrameMessage);
        },
        beforeUnmount() {
            window.removeEventListener('message', this.getFrameMessage);
        }
    });
</script>

<style scoped>
    .client_wrapper {
        overflow: hidden;
        max-height: calc(100vh - 184px);
        min-height: 200px;
        display: flex;
        flex-flow: column;
    }

    .tab_body {
        overflow: auto;
        padding-right: 5px;
    }

    .button {
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .button_back {
        padding: 8px 24px;
        min-width: 107px;
        margin-bottom: 12px;
    }

    .button_back span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .back_button_img {
        margin-right: 7px;
        margin-top: 1px;
        margin-left: -10px;
    }

    .profile_wrapper{
        display: flex;
    }

    .photo_wrapper{
        display: block;
        margin-right: 16px;
    }

    .photo {
        width: 172px;
        height: 172px;
        background-size: cover;
        margin: auto;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
    }

    .photo_text {
        text-align: center;
        line-height: normal;
        color: #65656F;
        margin-bottom: 8px;
    }

    .photo_button {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        color: white;
        width: 175px;
        margin: 0px 8px;
    }

    .data_wrapper{
        width: 100%;
    }

    .img_block {
        margin: 0px 8px;
    }

    .client_info_wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        border: 1px solid #D5D5D5;
        border-radius: 24px;
        margin-top: 18px;
        overflow: hidden;
    }

    .client_info_title {
        padding: 16px 24px;
        display: flex;
        background-color: #f6f7f8;
        justify-content: space-between;
        width: calc(100% - 48px);
    }

    .client_info_title_contract {
        padding: 16px 24px;
        display: flex;
        background-color: #f6f7f8;
        justify-content: space-between;
        width: calc(100% - 48px);
    }

    .client_info_fi {
        font-size: 28px;
        font-weight: 600;
        line-height: 36px;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        display: flex;
    }

    .client_info_phone {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
    }

    .edit_button {
        padding: 10px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .edit_img {
        height: 13px;
        width: 13px;
    }

    .client_info_body {
        padding: 0px 24px 16px 24px;
        display: flex;
    }

    .client_info_body_contract {
        padding: 0px 24px 16px 24px
    }

    .block_row {
        display: flex;
        justify-content: space-between;
    }

    .one_block_wrapper {
        display: flex;
        flex-flow: column;
        width: 100%;
    }

    .two_block_wrapper {
        display: flex;
        flex-flow: column;
        width: 50%;
    }

    .block_label {
        color: black;
        margin-top: 0px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        font-weight: bold;
    }

    .block_info {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        color: #65656F;
    }

    .first_block_wrapper{
        width: calc(50% - 8px);
        padding-right: 8px;
        display:flex;
        flex-flow:column;
    }

    .second_block_wrapper {
        width: calc(50% - 8px);
        padding-left: 8px;
        display: flex;
        flex-flow: column;
    }

    .dot_active {
        background-color: green;
        margin-top: 5.5px;
    }

    .dot_disable {
        background-color: black;
        margin-top: 5.5px;
    }

    .in_club_status {
        margin: 0;
        white-space: nowrap;
    }

    .club_name_info {
        width: unset;
    }

    .client_edit {
        display: flex;
        padding: 16px 24px 0px 24px;
        width: calc(100% - 48px);
        flex-flow: wrap;
    }

    .edit_client_elment_wrapper {
        width: 33%;
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
    }

    .edit_client_elment {
        width: 97% !important;
    }

    .edit_client_button_wrapper {
        display: flex;
        padding: 0px 24px 4px 24px;
    }

    .button_space {
        margin-right: 16px;
    }

    .client_info_agreement {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        cursor: pointer;
        text-decoration: underline;
    }

    .client_info_contract {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        margin: 0px;
        color: #65656F;
    }

    .client_info_contract_status {
        color: #65656F;
        margin-top: 4px;
        margin-left: 10px;
    }

    .contract_switch_button {
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 9px;
        height: 32px;
        width: 32px;
        justify-content: center;
    }

    .contract_switch_button_img {
        margin-top: 1px;
    }

    .contract_switch_button_disable {
        opacity: 0.4;
    }

    .contract_switch_text {
        margin: 7px 0px 0px 0px;
        letter-spacing: 2px;
    }

    .client_info_body_contract_data{
        display: flex;
    }

    .contract_payment_date {
        color: #65656F;
        margin-left: 12px;
    }

    .error_text{
        color: red !important;
    }

    .edit_menu {
        position: absolute;
        right: 0;
        display: flex;
        flex-flow: column;
        background-color: white;
        width: max-content;
        border: 1px solid #D5D5D5;
        border-radius: 12px;
        top: 34px;
        overflow:hidden;
    }

    .edit_menu_item {
        padding: 12px;
        cursor: pointer;
        border-top: 1px solid #D5D5D5;
    }

    .edit_menu_item:hover {
        background-color: #efefef;
    }

    .edit_menu_item:first-child {
        border-top: none;
    }

    .can_change_status {
        text-decoration: underline;
        cursor: pointer;
    }

    .client_info_contract_status_description {
        margin: 1px 0px 0px 0px;
        font-size: 14px;
        color: #65656F;
    }

    .contract_status_cancel_button {
        border: none;
        background-color: unset;
        padding: 0px;
        text-decoration: underline;
        cursor: pointer;
        color: #65656F;
        letter-spacing: 0.5px;
    }

    .services_wrapper {
        display: flex;
        width: 100%;
        flex-flow:wrap;
    }

    .service_wrapper {
        width: calc(50% - 8px);
    }

    .service_wrapper:nth-child(odd){
        padding-right: 8px;
    } 
    
    .service_wrapper:nth-child(even){
        padding-left: 8px;
    }

    .service {
        display: flex;
        padding: 12px 16px;
        background-color: #f6f7f8;
        border-radius: 16px;
        margin: 4px 0px;
        justify-content: space-between;
    }

    .service_data_wrapper {
        max-width: calc(100% - 120px);
        flex-flow: wrap;
        display:flex;
    }

    .client_info_servicecontract_status {
        color: #65656F;
    }

    .service_price {
        color: #65656F;
    }

    .client_info_comments {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin: 0px;
        margin-right: 12px;
    }

    .comments_add_button {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 12px;
        line-height: 24px;
        color: white;
        width: 110px;
        height: 33px;
    }

    .comments_title{
        width: 100%;
        display: flex;
    }

    .comment_wrapper {
        border-bottom: 1px solid #D5D5D5;
        width: 100%;
        padding-top: 12px;
    }

    .comment_wrapper:last-child {
        border-bottom: none;
        width: 100%;
        padding-top: 12px;
    }

    .comment_wrapper:last-child .comment_text {
        margin: 6px 0px 0px 0px
    }

    .comment_author {
        margin: 0;
        color: #65656F;
        padding-right: 25px;
    }

    .comment_date {
        margin: 0;
        color: #65656F;
        font-size: 12px;
    }

    .client_info_comment_body {
        padding: 0px 24px 16px 24px;
        max-height: 215px;
        overflow: auto;
    }

    .comment_text {
        margin: 6px 0px 12px 0px
    }

    .client_info_contract_previous_payment_queue {
        color: #65656F;
        font-size: 12px;
    }

    .privacy_wrapper {
        display: flex;
        width: 100%;
        flex-flow: wrap;
    }

    .privacy{
        width: calc(50% - 8px);
        margin-top: 18px;
    }

    .privacy:nth-child(odd) {
       padding-right: 8px;
    }
    
    .privacy:nth-child(even) {
        padding-left: 8px;
    }

    .privacy_label {
        color: black;
        margin-top: 0px;
        margin-bottom: 8px;
        font-weight: bold;
    }

    .total_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .total {
        color: #65656F;
        margin: 0;
        font-weight: 600;
        padding-left: 8px;
    }

    .new {
        color: #00a3ba;
        cursor: pointer;
        text-decoration: underline;
    }

    .new_mobile_button {
        display: none;
    }

    .paymeant_error {
        background-color: #FBE0E0;
        color: #E22E2E;
        border-left: 4px solid #E22E2E;
        border-radius: 4px;
        display: flex;
        width: 516px;
        padding: 8px 16px;
        gap: 4px;
        margin: 24px 0px;
    }

    .payment_select_wrapper {
        border-radius: 12px;
        display: flex;
        border: 1px solid #D5D5D5;
        width: fit-content;
    }

    .payment_button_active {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        color: white;
        width: 125px;
        margin: 0px;
    }

    .payment_button {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: white;
        font-size: 16px;
        line-height: 24px;
        color: black;
        width: 125px;
        margin: 0px;
    }

    .payment_change_button {
        display: flex;
        padding: 7px 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        color: white;
        margin: 0px;
        width: 266px;
    }

    .payment_buttons_wrapper{
        display: flex;
        justify-content: space-between;
    }

    .change_payment_frame_wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #b5b4b466;
        z-index: 11111;
    }

    .change_payment_frame {
        position: fixed;
        background-color: white;
        top: 30px;
        height: calc(100vh - 60px);
        width: 400px;
        left: 50%;
        transform: translateX(-50%);
        border: none;
        scrollbar-width: thin;
        scrollbar-color: #FFFFFF #ececec;
    }

    .filter_wrapper {
        display: flex;
    }

    .filter_right_wrapper {
        display: flex;
        padding-left: 8px
    }

    .filter_left_wrapper {
        width: 100%;
        display: flex;
        padding-right: 8px
    }

    .small_filter_input {
        width: 266px !important;
    }

    .status_filter_input {
        min-width: 266px !important;
        width: 266px !important;
        margin-left: 16px;
    }

    .clear_all_filters {
        color: #21A7D1;
        cursor: pointer;
        margin: 8px 0;
        width: fit-content;
    }

    .filter_button {
        display: none;
    }

    .training_left_filter {
        width: calc(50% - 16px);
        margin-right: 16px;
    }

    .training_right_filter {
        width: 50%;
    }

    .paymeant_mean {
        border-radius: 12px;
        border: 1px solid #D5D5D5;
        width: fit-content;
        padding: 10px 18px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .paymeant_mean_data {
        margin-top: 0px;
        margin-bottom: 8px;
        color: #65656F;
    }

    .paymeant_mean_data span {
        font-weight: 600;
        color: black;
    }

    .comment_title{
        position: relative
    }

    .delete_comment {
        position: absolute;
        top: 1px;
        right: 0px;
        cursor: pointer;
    }

    .table_orders_wrapper {
        overflow: auto;
        padding-right: 10px;
        max-height: calc(100vh - 562px);
        min-height: 85px;
    }

    .table_orders_wrapper_without_active_payment_mean {
        overflow: auto;
        padding-right: 10px;
        max-height: calc(100vh - 500px);
        min-height: 85px;
    }

    .table_trainers_wrapper {
        overflow: auto;
        padding-right: 10px;
        max-height: calc(100vh - 376px);
        min-height: 85px;
    }

    .frame_close_button {
        top: 39px;
        left: calc(50% + 170px);
        transform: translateX(-50%);
        position: absolute;
        cursor: pointer;
    }

    .camera {
        cursor: pointer;
        height: 36px;
        width: 36px;
        min-width: 36px;
        border-radius: 50%;
        margin-left: 15px;
        background-color: white;
        border: 1px solid #878787;
        position: absolute;
        right: -10px;
        top: 20px;
    }

    .camera_img {
        height: 18px;
        margin-top: 4px;
    }

    .top_buttons_wrapper_space_between {
        display: flex;
        justify-content: space-between;
    }

    .top_buttons_wrapper_end {
        display: flex;
        justify-content: end;
    }

    .link_card_button {
        color: #00a3ba;
        cursor: pointer;
        text-decoration: underline;
        margin-top: 5px;
    }

    .untie_card {
        color: #df2793;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 12px;
        margin-top: 5px;
    }

    .active_pass_registration_button{
        cursor: pointer;
        text-decoration:underline;
    }

    .can_change_client_sourse{
        text-decoration: underline;
        cursor: pointer;
    }

    .restriction_alert {
        position: relative;
        width: 30px;
        height: 30px;
        display: block;
        margin-left: 12px;
    }

    .restriction_alert img{
        height: 30px;
        width: 30px;
        position: absolute;
        top: 3px;
        left: 0px;
        cursor: pointer;
    }

    @media(max-width: 1200px) {
        .small_filter_input {
            width: 50% !important;
        }

        .status_filter_input {
            min-width: 49% !important;
        }


        .filter_wrapper {
            display: block;
        }

        .filter_right_wrapper {
            margin-top: 12px;
            padding-left: 0px
        }

        .filter_left_wrapper {
            padding-right: 0;
        }

        .training_left_filter {
            width: 50%;
        }

        .profile_wrapper {
            display: block;
        }

        .img_wrapper {
            display: flex;
            justify-content: center;
        }

        .photo_buttons_wrapper {
            display: flex;
            justify-content: center;
        }

        .client_info_fi {
            font-size: 26px;
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
            width: unset;
            line-height: 28px;
            overflow-wrap: anywhere;
            padding-right: 10px;
        }

        .restriction_alert {
            width: 26px;
            height: 26px;
        }

        .restriction_alert img {
            height: 26px;
            width: 26px;
            top: 2px;
        }

        .client_info_phone {
            font-size: 18px;
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
            width: unset;
            margin-top: 2px;
        }

        .last_viewed_client_hover_end {
            right: 0px;
            left: unset;
        }

        .table_orders_wrapper {
            max-height: calc(100vh - 611px);
        }

        .table_orders_wrapper_without_active_payment_mean {
            max-height: calc(100vh - 549px);
        }

        .table_trainers_wrapper {
            max-height: calc(100vh - 425px);
        }

        .camera {
            right: -7px;
            top: 30px;
        }
    }

    @media(max-width: 1000px) {
        .edit_client_elment_wrapper {
            width: 50%;
        }
    }

    @media(max-width: 900px) {
        .block_row {
            display: block;
        }

        .two_block_wrapper {
            width: 100%;
        }

        .edit_client_elment_wrapper {
            width: 100%;
        }

        .edit_client_button_wrapper {
            justify-content: center;
        }

        .edit_client_elment {
            width: 100% !important;
        }

        .service_wrapper {
            width: 100%;
        }

        .service_wrapper:nth-child(odd) {
            padding-right: 0px;
        }

        .service_wrapper:nth-child(even) {
            padding-left: 0px;
        }

        .privacy {
            width: 100%;
        }

        .privacy:nth-child(odd) {
            padding-right: 0px;
        }

        .privacy:nth-child(even) {
            padding-left: 0px;
        }
    }

    @media(max-width: 768px) {
        .payment_buttons_wrapper{
            display: block;
        }

        .payment_change_button {
            width: 100%;
            margin-top: 12px;
        }

        .payment_select_wrapper{
            width: 100%;
        }

        .payment_button {
            width: 50%;
        }

        .payment_button_active {
            width: 50%;
        }

        .paymeant_error {
            width: calc(100vw - 120px);
        }

        .client_wrapper {
            max-height: calc(100vh - 168px);
        }

        .table_orders_wrapper {
            max-height: calc(100vh - 645px);
        }

        .table_orders_wrapper_without_active_payment_mean {
            max-height: calc(100vh - 583x);
        }

        .table_trainers_wrapper {
            max-height: calc(100vh - 410px);
        }
    }

    @media(max-width: 650px) {
        .client_info_body{
            display: block;
        }

        .first_block_wrapper {
            width: 100%;
            padding-right: 0px;
        }

        .second_block_wrapper {
            width: 100%;
            padding-left: 0px;
        }

        .club_name_wrapper{
            margin-top: 12px;
        }

        .client_info_contract{
            display: none;
        }

        .client_info_contract_status{
            margin-left: 0px;
        }

        .client_info_body_contract_data {
            display: block;
        }

        .client_info_contract_status_description {
            margin: 4px 0px 0px 0px;
        }

        .client_wrapper {
            max-height: calc(100vh - 130px);
        }

        .table_orders_wrapper {
            max-height: calc(100vh - 607px);
        }

        .table_orders_wrapper_without_active_payment_mean {
            max-height: calc(100vh - 545px);
        }

        .table_trainers_wrapper {
            max-height: calc(100vh - 371px);
        }
    }

    @media(max-width: 500px) {
        .photo {
            width: 125px;
            height: 125px;
        }

        .photo_buttons_wrapper{
            display: none;
        }

        .client_info_fi {
            font-size: 17px;
            line-height: 17px;
        }

        .restriction_alert {
            width: 17px;
            height: 17px;
        }

        .restriction_alert img {
            height: 17px;
            width: 17px;
            top: 1px;
        }

        .client_info_phone {
            font-size: 14px;
        }

        .edit_client_button_wrapper{
            display: block;
        }

        .button_space {
            margin-right: 0px;
        }

        .client_info_agreement {
            font-size: 15px;
            line-height: 17px;
            overflow: unset;
            white-space: unset;
            text-overflow: unset;
            width:unset;
        }

        .client_info_contract_status {
            font-size: 12px;
        }

        .dot {
            width: 6px;
            height: 6px;
            margin-top: 3px;
        }

        .contract_switch_text {
            letter-spacing: 2px;
        }

        .client_info_contract_status_description {
            font-size: 10px;
        }

        .contract_status_cancel_button {
            font-size: 10px;
        }

        .service {
            flex-flow: column-reverse;
        }

        .service_data_wrapper {
            max-width: 100%;
            margin-top: 6px;
            flex-flow: column;
        }

        .service_price {
            margin-top: 6px !important;
        }

        .client_info_servicecontract_status .dot {
            margin-top: 6px;
        }

        .comments_add_button {
            width: 33px;
        }

        .comments_add_button_text {
            display: none;
        }

        .comments_show_button{
            display: none;
        }

        .comments_title {
            justify-content: space-between;
        }

        .new {
            display: none
        }

        .new_mobile_button {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #00a3ba;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
            margin-top: 12px;
            width: 100%;
            color: white;
        }

        .filter_right_wrapper {
            display: block;
        }

        .filter_left_wrapper {
            display: block;
        }

        .small_filter_input {
            width: 100% !important;
        }

        .status_filter_input {
            min-width: 100% !important;
            margin-left: 0px;
            margin-top: 12px;
        }

        .date_input {
            margin-top: 16px;
        }

        .show_filtes_mobile {
            display: block;
        }

        .hide_filtes_mobile {
            display: none;
        }

        .filter_button {
            display: flex;
            width: 100%;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #f2f2f2;
            font-size: 16px;
            line-height: 24px;
        }

        .total {
            padding-left: 0px;
        }

        .paymeant_mean {
            width: unset;
        }

        .paymeant_mean_data {
            font-size: 12px;
        }

        .table_orders_wrapper {
            max-height: calc(100vh - 512px);
        }

        .table_orders_wrapper_without_active_payment_mean {
            max-height: calc(100vh - 450px);
        }

        .table_trainers_wrapper {
            max-height: calc(100vh - 300px);
        }

        .training_left_filter{
            width: 100%;
            margin-bottom: 10px;
        }

        .training_right_filter {
            width: 100%;
        }

        .change_payment_frame {
            top: 0px;
            height: calc(100vh);
            width: 100%;
            left: 0px;
            transform: unset;
        }

        .frame_close_button {
            top: 7px;
            left: unset;
            right: 14px;
        }

        .camera {
            display: none;
        }
    }

    :deep(.refferal_client) {
        cursor: pointer;
        text-decoration: underline;
        color: #65656F;
    }

    :deep(.retry_to_pay_button) {
        width: 84px;
        margin-right: 5px;
        padding: 5px 15px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 26px;
    }

    :deep(.client_block_paid) {
        display: flex;
    }

    @media(max-width: 500px) {
        :deep(.mobile_hide) {
            display: none;
        }

        :deep(.mobile_device_os) {
            background-color: #f5f5f5;
            padding: 1px 7px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            font-size: 11px;
            letter-spacing: 1px;
        }

        :deep(.mobile_device_version) {
            font-weight: bold;
            margin-bottom: 10px;
        }

        :deep(.dot_table) {
            display: none;
        }

        :deep(.status_text_new) {
            background-color: white;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
            border: 0.1px solid;
        }

        :deep(.status_text_active) {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
        }

        :deep(.status_text_frozen) {
            background-color: #e1e1ff;
            padding: 4px 10px;
            border-radius: 12px;
            color: blue;
        }

        :deep(.status_text_indebt) {
            background-color: #fff1d7;
            padding: 4px 10px;
            border-radius: 12px;
            color: orange;
        }

        :deep(.status_text_blocked) {
            background-color: #b9b94b;
            padding: 4px 10px;
            border-radius: 12px;
            color: yellow;
        }

        :deep(.status_text_finished) {
            background-color: #b4b4b4;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
        }

        :deep(.status_text_terminated) {
            background-color: #efefef;
            padding: 4px 10px;
            border-radius: 12px;
            color: gray;
        }

        :deep(.status_text_refunded) {
            background-color: #ffe5e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: red;
        }

        :deep(.date) {
            background-color: #f5f5f5;
            padding: 1px 11px;
            border-radius: 12px;
            color: black;
            margin-bottom: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            min-width: 149px;
            display: flex;
        }

        :deep(.contract_freeze_price) {
            display: none;
        }

        :deep(.contract) {
            font-weight: bold;
        } 
        
        :deep(.club) {
            margin-bottom: 10px;
        }

        :deep(.order_title) {
            font-weight: 600;
            margin-bottom: 8px;
        }

        :deep(.order_contract) {
            color: #0d99ff;
            margin-bottom: 5px;
            margin-top: 10px;
        }

        :deep(.order_payments_processing_provider) {
            color: #a19d9d;
        }

        :deep(.order_status_text_not_processed) {
            background-color: #b9b94b;
            padding: 4px 10px;
            border-radius: 12px;
            color: yellow;
            margin-top: 10px;
        }

        :deep(.order_status_text_processing) {
            background-color: #e1e1ff;
            padding: 4px 10px;
            border-radius: 12px;
            color: blue;
            margin-top: 10px;
        }

        :deep(.order_status_text_cancelled) {
            background-color: #b4b4b4;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
        }

        :deep(.order_status_text_paid) {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
            margin-top: 10px;
        }

        :deep(.order_status_text_not_paid) {
            background-color: #efefef;
            padding: 4px 10px;
            border-radius: 12px;
            color: gray;
            margin-top: 10px;
        }

        :deep(.order_status_text_refunded) {
            background-color: #ffe5e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: red;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_not_processed) {
            background-color: #b9b94b;
            padding: 4px 10px;
            border-radius: 12px;
            color: yellow;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_processing) {
            background-color: #e1e1ff;
            padding: 4px 10px;
            border-radius: 12px;
            color: blue;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_cancelled) {
            background-color: #b4b4b4;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_refunded) {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_not_refunded) {
            background-color: #ffe5e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: red;
            margin-top: 10px;
        }

        :deep(.training_title) {
            font-weight: 600;
            margin-bottom: 8px
        }

        :deep(.training_trainer) {
            color: #0d99ff;
            margin-bottom: 5px
        }

        :deep(.training_zone) {
            color: #a19d9d;
            font-size: 14px;
        }

        :deep(.training_block_name) {
            font-weight: bold;
            margin-bottom: 10px;
        }

        :deep(.training_block_used) {
            display: flex;
            color: #a19d9d;
            margin-top: 10px;
        }

        :deep(.training_block_training_class) {
            letter-spacing: 1px;
        }

        :deep(.training_block_trainer) {
            color: #0d99ff;
            margin-bottom: 5px;
        }

        :deep(.client_block_paid) {
            background-color: #f5f5f5;
            padding: 1px 11px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }

        :deep(.retry_to_pay_button) {
            margin-top: 10px;
        }

        :deep(.event_user) {
            margin-bottom: 10px;
            font-weight: bold;
        }

        :deep(.event_type) {
            color: #0d99ff;
            margin-bottom: 10px;
        }

        :deep(.email_and_phone) {
            color: #0d99ff;
            margin-top: 5px;
        }

        :deep(.refferal_client) {
            color: black;
            font-weight: bold
        }

        :deep(.refferal_client_contract) {
            margin-bottom: 10px;
            margin-top: 5px;
        }
    }
</style>