<template src="./viewOrder.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl, getFormatedAmount, getFormatedDate, getOrderStatus, getOrderPaymentsProcessingProvider, generateOrderStatus, retryGetPermissions, generateRefundOrderStatus } from '../../services/helper';
    import { Routes } from '../../const';
    import { Order } from '../../models/Order';
    import { TableColum, TableRow } from '../../models/Table'

    interface ComponentModel {
        orderId: number,
        order: Order | null,
        showAlert: boolean,
        showPOSAlert: boolean,
        showCashAlert: boolean,
        orderItemsColumTemplates: TableColum[],
        orderItemsTableData: TableRow[],
        refundOrderColumTemplates: TableColum[],
        refundOrderTableData: TableRow[],
        isLoaded: boolean,
        isButtonDisabled: boolean,
        hasResumePermission: boolean,
        hasCancelPermission: boolean,
        hasRefundPermission: boolean,
        hasPayPermission: boolean
        canPayOrder: boolean
    }

    export default defineComponent({
        name: 'ViewOrder',
        data(): ComponentModel {
            return {
                orderId: 0,
                order: null,
                showAlert: false,
                showPOSAlert: false,
                showCashAlert: false,
                orderItemsColumTemplates: [
                    { title: 'order.name', type: 'text', isSortable: true, template: '<div class="order_item_name">%data%</div>' },
                    { title: 'order.amount', type: 'text', isSortable: true, template: '<div>%data%</div>' },
                    { title: 'order.vat', type: 'text', isSortable: true, template: '<div class="order_item_vat">%data%</div>' },
                    { title: 'order.type', type: 'text', isSortable: true, template: '<div class="order_item_type">%data%</div>' },
                ],
                orderItemsTableData: [],
                refundOrderColumTemplates: [
                    { title: 'order.order', type: 'text', isSortable: true, template: '<div class="order_item_name">%data%</div>' },
                    { title: 'order.date', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'order.amount', type: 'text', isSortable: true, template: '<div>%data%</div>' },
                    { title: 'order.description', type: 'text', isSortable: true, template: '<div class="description">%data%</div>' },
                    { title: 'order.status', type: 'refund_order_status', isSortable: true, template: '<div class="flex refund_order_status_text_%color%"><div class="dot dot_table dot_refund_order_%color%"></div>%data%</div>' }
                ],
                refundOrderTableData: [],
                hasResumePermission: false,
                hasCancelPermission: false,
                hasRefundPermission: false,
                hasPayPermission: false,
                isLoaded: false,
                isButtonDisabled: false,
                canPayOrder: false
            }
        },
        methods: {
            getOrder: async function () {
                await axiosRequests.get(editUrl(Routes.getOrder, this.orderId))
                    .then(
                        (data: Order) => {
                            this.order = data;

                            this.orderItemsTableData = this.order.orderItems.map(x => { return { id: "", data: [x.name, getFormatedAmount(x.amount) + " " + data.currencySign, x.vat ? parseInt((x.vat / 100).toString()) + "%" : this.$t('order.not_applicable'), this.$t(x.serviceTypeName)] } });

                            if(this.order.refundOrder) {
                                 this.refundOrderTableData = [{ id: this.order.refundOrder.refundOrderId.toString(), data: [this.order.refundOrder.refundOrderId.toString().padStart(8, '0'), new Date(this.order.refundOrder.dueDate), `${getFormatedAmount(this.order.refundOrder?.total)} ${this.order.refundOrder.currencySign}`, this.order.refundOrder.comment, generateRefundOrderStatus(this.order.refundOrder.processingStatus, this.order.refundOrder.refundStatus) ] }];
                            }

                            this.canPayOrder = (data.queueDate > data.dueDate && data.processingStatus == 0) || (data.isInitial && !data.isBankCardChange && data.processingStatus == 2 && data.paidStatus == 0)
                            this.isLoaded = true;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            changeOrderStatus: async function () {
                this.showAlert = false;
                this.isButtonDisabled = true;

                try {
                    if (this.order?.processingStatus == 2 && this.order?.paidStatus == 1 && !this.order?.isRefunded) {
                       await axiosRequests.post(editUrl(Routes.refundOrder, this.orderId))
                    }

                    if (this.order?.processingStatus == -1) {
                        await axiosRequests.post(editUrl(Routes.resumeOrder, this.orderId))
                    }

                    if (this.order?.processingStatus == 0) {
                        await axiosRequests.post(editUrl(Routes.cancelOrder, this.orderId))
                    }

                    await this.getOrder();
                }
                catch (err: any) {
                    if (err.status == 401) {
                        return;
                    } else if (err.status == 403) {
                        this.$router.push('/forbidden');
                    } else if (err.status == 400 && err.data) {
                        this.$store.commit('SET_ADD_ERROR', err.data);
                    } else {
                        this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                    }
                }
                finally {
                    this.isButtonDisabled = false;
                }
            },
            openRefundOrder: function (id: string) {
                if (!id) return;

                this.$router.push('/refundorder/' + id);
            },
            pay: async function (type: string) {
                this.showPOSAlert = false;
                this.showCashAlert = false;
                this.isButtonDisabled = true;

                await axiosRequests.post(editUrl(`${Routes.payForOrder}?paymentMethod=${type}`, this.orderId))
                    .then(
                        () => {
                            this.isButtonDisabled = false;

                            this.getOrder();
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            redirectToLastViewedClient: function (clientId: number, isDelete: boolean = false) {
                if (clientId != this.order?.clientId) {
                    if (!isDelete) this.$store.commit('UNSHIFT_LAST_VIEWED_CLIENT', clientId);

                    this.$router.push('/client/' + clientId);
                }
            },
            deleteLastViewedClient: function (index: number) {
                if (index == 0) {
                    if (this.lastViewedClients.length == 1) this.$router.push('/clients');
                    else this.redirectToLastViewedClient(this.lastViewedClients[1].id, true);
                }

                this.$store.commit('SET_REMOVE_LAST_VIEWED_CLIENT', index);
            },
            getFormatedAmount,
            getFormatedDate,
            getOrderStatus,
            getOrderPaymentsProcessingProvider,
            generateOrderStatus
        },
        watch: {
            '$route.params.id': {
                handler: async function (id) {
                    if (!id) return;

                    this.orderId = id;

                    await this.getOrder();
                },
                deep: true,
                immediate: true
            }
        },
        computed: {
            lastViewedClients: function () {
                return this.$store.getters.getLastViewedClients;
            }
        },
        async beforeMount() {
            let orderPermission = this.$store.getters.getOrdersPermission;

            if (orderPermission.length == 0) orderPermission = await retryGetPermissions("order");

            if (!orderPermission?.includes('orders/{id}/detailed')) this.$router.push('/forbidden');

            this.hasResumePermission = orderPermission?.includes('orders/{id}/resume');
            this.hasCancelPermission = orderPermission?.includes('orders/{id}/cancel');
            this.hasRefundPermission = orderPermission?.includes('orders/{id}/refund');
            this.hasPayPermission = orderPermission?.includes('orders/{id}/pay');
        },
    });
</script>

<style scoped>
    .order_page_wrapper {
        padding-right: 5px;
        overflow: auto;
    }

    .order_wrapper {
        overflow: hidden;
        max-height: calc(100vh - 184px);
        min-height: 200px;
        display: flex;
        flex-flow: column;
    }

    .button {
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .button_back {
        width: 107px;
        margin-bottom: 12px;
    }

    .back_button_img {
        margin-right: 7px;
        margin-top: 1px;
        margin-left: -10px;
    }

    .order {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin: 0px;
    }

    .order_data {
        display: flex;
        flex-flow:wrap;
    }

    .order_data_block {
        width: 25%;
    }

    .block_label {
        color: black;
        margin-top: 0px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        font-weight: bold;
    }

    .block_info {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        color: #65656F;
    }

    .order_status {
        margin-top: -1px;
        margin-left: 10px;
    }

    .order_title {
        display: flex;
    }

    .status_text_not_processed {
        background-color: #fff1d7;
        padding: 4px 10px;
        border-radius: 12px;
        color: orange;
    }

    .status_text_processing {
        background-color: #e1e1ff;
        padding: 4px 10px;
        border-radius: 12px;
        color: blue;
    }

    .status_text_cancelled {
        background-color: #b4b4b4;
        padding: 4px 10px;
        border-radius: 12px;
        color: black;
    }

    .status_textt_not_processed {
        background-color: #b9b94b;
        padding: 4px 10px;
        border-radius: 12px;
        color: yellow;
    }

    .status_text_processing {
        background-color: #e1e1ff;
        padding: 4px 10px;
        border-radius: 12px;
        color: blue;
    }

    .status_text_cancelled {
        background-color: #b4b4b4;
        padding: 4px 10px;
        border-radius: 12px;
        color: black;
    }

    .status_text_paid {
        background-color: #def8e5;
        padding: 4px 10px;
        border-radius: 12px;
        color: green;
    }

    .status_text_not_paid {
        background-color: #efefef;
        padding: 4px 10px;
        border-radius: 12px;
        color: gray;
    }

    .status_text_refunded {
        background-color: #ffe5e5;
        padding: 4px 10px;
        border-radius: 12px;
        color: red;
    }

    .order_cancel_button {
        display: flex;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #e8e805;
        line-height: 24px;
        color: #2c2c09;
        min-width: 110px;
        height: 33px;
        margin-top: 18px;
    }

    .order_cancel_button:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    .order_resume_button {
        display: flex;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #008000;
        line-height: 24px;
        color: white;
        min-width: 110px;
        height: 33px;
        margin-top: 18px;
    }

    .order_resume_button:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    .order_refund_button {
        display: flex;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: red;
        line-height: 24px;
        color: white;
        min-width: 110px;
        height: 33px;
        margin-top: 18px;
    }

    .order_refund_button:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    .order_pay_button {
        display: flex;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #21a7d1;
        line-height: 24px;
        color: white;
        min-width: 110px;
        height: 33px;
        margin-top: 18px;
        margin-right: 12px;
    }

    .button_wrapper{
        display: flex;
    }

    .order_status_span_red{
        color: red;
    }

    @media(max-width: 860px) {
        .order_data_block {
            width: 50%;
        }
    }

    @media(max-width: 650px) {
        .order_data_block {
            width: 100%;
        }

        .order_status {
            margin-left: 0px;
            margin-top: 4px;
            width: fit-content;
        }

        .order_title {
            display: block;
        }

        .button_wrapper {
            display: block;
        }

        .order_pay_button {
            margin-right: 0px;
            width: 100%;
        }

        .order_cancel_button {
            width: 100%;
        }

        .order_resume_button {
            width: 100%;
        }

        .order_refund_button {
            width: 100%;
        }
    }

    @media(max-width: 500px) {
        :deep(.order_item_name) {
            font-weight: bold;
            margin-bottom: 10px;
        }

        :deep(.order_item_vat) {
            color: #a19d9d;
            font-size: 14px;
            margin-top: 10px;
        }

        :deep(.order_item_type) {
            background-color: #f5f5f5;
            padding: 1px 7px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }


        :deep(.refund_order_status_text_not_processed) {
            background-color: #b9b94b;
            padding: 4px 10px;
            border-radius: 12px;
            color: yellow;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_processing) {
            background-color: #e1e1ff;
            padding: 4px 10px;
            border-radius: 12px;
            color: blue;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_cancelled) {
            background-color: #b4b4b4;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_refunded) {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
            margin-top: 10px;
        }

        :deep(.refund_order_status_text_not_refunded) {
            background-color: #ffe5e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: red;
            margin-top: 10px;
        }

        :deep(.date) {
            background-color: #f5f5f5;
            padding: 1px 11px;
            border-radius: 12px;
            color: #000;
            margin-bottom: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }

        :deep(.description) {
            color: #a19d9d;
            font-size: 14px;
            margin-top: 10px;
        }
    }
</style>