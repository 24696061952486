<template>
    <div class="load_bar_wrapper" v-show="load"><div class="load_bar"></div></div>
    <div class="header" v-show="!isLoading">
        <div class="logo_wrapper">
            <div class="flex logo_buttons_wrapper" v-if="isAuth">
                <img class="setting_button_mobile pointer" src="/assets/img/setting.svg" @click="clickOnOptionsButton" />
                <img :class="!isShowMenu ? 'hamburger_button mobile_show' : 'hamburger_button mobile_hide'" src="/assets/img/hamburger-button.svg" @click="isShowMenu = !isShowMenu" />
                <img :class="isShowMenu ? 'close_button pointer mobile_show' : 'close_button pointer mobile_hide'" src="/assets/img/close.svg" @click="isShowMenu = !isShowMenu" />
            </div>

            <div class="flex">
                <div class="img_wrapper">
                    <img class="logo_img" src="/assets/img/logo.svg" v-if="networkLogo == null" />
                    <img class="network_logo" v-if="networkLogo != null" :src="networkLogo">
                </div>
               
                <p class="header_title" v-if="!isAuth">{{$t('main.title')}}</p>
            </div>

        </div>
        <div class="search_wrapper" v-if="isAuth">
            <v-input v-model="searchText" :placeholder="$t('main.search')" inputType="text" :height="20" search @update:modelValue="search"></v-input>

            <div class="current_club_wrapper" ref="clubSelect" v-show="availableClubs.length > 0">
                <v-select v-model="selectedClub" :label="'main.current_club'" class="w-100" :items="availableClubs" @update:modelValue="selectClub" labelWithEllipsis></v-select>
            </div>

            <button class="setting_button" @click="clickOnOptionsButton"><img class="setting_button_img" src="/assets/img/setting.svg" /></button>

            <button @click="findCardOwner" v-if="hasConnection" class="setting_button"><img class="setting_button_img" src="/assets/img/card-search.svg" /></button>
        </div>

        <div class="user_wrapper" v-if="isAuth">
            <div>
                <p class="user_name">{{currentUser?.lastName || ''}} {{currentUser?.firstName || ''}}</p>
                <p class="user_role" v-if="currentUser?.mainRoleName">{{$t(currentUser?.mainRoleName)}}</p>
            </div>
            <div class="ml-3">
                <div class="user_photo" :style="{ 'background-image': `url(${currentUser?.photoUrl})` }"></div>
            </div>
        </div>

        <div :class="isOptionsOpen ? 'options_wrapper options_mobile_wrapper_show' : 'options_wrapper options_mobile_wrapper_hidden'" ref="options" v-show="isOptionsOpen" v-click-outside="onClickOutsideOptions">

            <div class="flex">
                <p class="mt-0 mr-2">{{$t('main.ru')}}</p>
                <label class="switcher">
                    <input type="checkbox" v-model="isEnLanguageSelect" @change="switchLanguage" hidden>
                    <span class="switcher_switch"></span>
                </label>

                <p class="mt-0 ml-2">{{$t('main.en')}}</p>
            </div>
            <v-checkbox v-model="isAcceptCalls" :label="$t('main.accept_calls')" @click="clickOnAcceptCalls"></v-checkbox>
            <div class="accept_calls_club_select" ref="acceptCallClubSelect" v-show="isAcceptCalls">
                <v-select v-model="selectedClubForCalls" :label="'main.club_for_calls'" class="w-100 mt-3" :items="availableClubsForCalls" @update:modelValue="selectAcceptCallsClub" v-show="availableClubs.length > 0" @click="resizeOptionWindow" @optionsClosed="resizeOptionWindow"></v-select>
            </div>

        </div>
    </div>

    <div class="body flex mt-4" v-if="isAuth && !isLoading">
        <div :class="isShowMenu ? 'menu_wrapper menu_wrapper_show' : 'menu_wrapper menu_wrapper_hide'" v-click-outside="clickOutsideMenu">
            <div class="menu_mobile_search">
                <div class="hr mb-4"></div>
                <v-input v-model="searchText" :placeholder="$t('main.search')" inputType="text" search @update:modelValue="search"></v-input>
                <v-select v-show="availableClubs.length > 0" v-model="selectedClub" :label="'main.current_club'" class="w-100 mt-3" :items="availableClubs" @update:modelValue="selectClub"></v-select>
                <div class="hr mt-4 mb-4"></div>
            </div>

            <div v-for="item in menu" :key="item.text" class="menu_item_wrapper">
                <div :class="item.url == activeMenuItem ? 'menu_item menu_active' : 'menu_item'" @click="clickOnMenuItem(item)">
                    <img :src="'/assets/img/' + item.icon"> {{$t(item.text)}}
                    <div v-show="item.url == '/chats' && item.url != activeMenuItem && unreadChatMessageCount != 0" class="unread_messages_count"><p class="mt-0">{{unreadChatMessageCount > 9 ? '9+' :unreadChatMessageCount}}</p></div>
                    <img v-if="item.hasSubItems" class="menu_item_arrow" :src="item.isOpen ? '/assets/img/arrow-up.svg' : '/assets/img/arrow-down.svg'" />
                </div>
                <div v-for="subItem in item.subItems" :key="subItem.text" v-show="item.isOpen" @click="clickOnMenuItem(subItem)">
                    <div :class="subItem.url == `${activeMenuItem}${activeSubmenuItem}` ? 'menu_item pl-8 menu_active' : 'menu_item pl-8'">{{$t(subItem.text)}}</div>
                </div>
            </div>
            <div>
                <div class="hr mb-6"></div>
                <div class="menu_item mb-0" @click="signOut">
                    <img src="/assets/img/logout.svg"> {{$t('menu.sign_out')}}
                </div>
            </div>
        </div>
        <div :class="isShowMenu ? 'body_content body_content_with_menu' : 'body_content body_content_without_menu'">
            <div v-show="!isShowInternalServerError" v-if="!isUpdating"><router-view /></div>
            <page500 v-show="isShowInternalServerError"></page500>
        </div>

    </div>

    <div class="body" v-if="!isAuth && !isLoading">
        <div class="auth_wrapper content_wrapper">
            <h1 class="m-0">{{$t('main.sign_in')}}</h1>
            <p class="auth_text">{{$t('main.sign_in_text')}}</p>
            <v-input class="mt-8" v-model="login" :label="$t('main.login')" :placeholder="$t('main.login_placeholder')" inputType="text" :errorText="loginErrorText" height="28" @focus="loginErrorText = ''" @pressEnter="signIn" clearable></v-input>
            <v-input class="mt-7" v-model="password" :label="$t('main.password')" :placeholder="$t('main.password_placeholder')" :errorText="passwordErrorText" :inputType="passwordInputType" @changeType="changeType" height="28" @pressEnter="signIn" password @focus="passwordErrorText = ''"></v-input>
            <button :class="isLoginButtonDisable ? 'auth_button disable_button' : 'auth_button'" @click="signIn" :disable="isLoginButtonDisable">{{$t('main.sign_in_button')}}</button>
        </div>
    </div>

    <div class="calling_wrapper" v-show="isAuth && !isLeadOpen && leadCalling?.isCallingNow" id="callingButton" @click="clickOnCallingButton">
        <img class="m-auto" src="/assets/img/phone-calling.svg" />
    </div>

    <div class="even_messages_wrapper">
        <div class="error" v-for="error in errors" :key="error">
            <p class="my-0">{{$t(error)}}</p>
            <img class="error_close" src="/assets/img/close-red.svg" @click="closeError(error)" />
        </div>

        <div class="success" v-for="successMessage in successMessages" :key="successMessage">
            <p class="my-0">{{$t(successMessage)}}</p>
            <img class="success_close" src="/assets/img/close-green.svg" @click="closeSuccessMessages(successMessage)" />
        </div>
    </div>

    <passCardAlert :title="'pass_card.pass_card_alert.find_card'" v-if="isShowFindCard" @close="closeFindCard"></passCardAlert>
</template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue';
    import { useCookies } from "vue3-cookies";
    import { axiosRequests } from './services/axiosinstance';
    import { editUrl, getUrlPlural } from './services/helper';
    import { Routes } from './const';
    import { switchLanguage, getCurrentLocal } from '@/i18n';
    import { Token } from './models/Token';
    import { User } from './models/User';
    import { Menu } from './models/Menu';
    import { Club } from './models/Club';
    import { LeadCalling } from './models/LeadCalling';
    import { SelectModel } from './models/GUIModels';
    import ViewPage500 from './components/ServicePages/Page500.vue';
    import PassCardAlert from './components/PassCard/PassCardAlert/PassCardAlert.vue';
    import * as signalR from '@microsoft/signalr'

    interface MenuWrapper extends Menu  {
        hasSubItems: boolean,
        isOpen: boolean
    }

    interface FindPassCardModel {
        clientId: number,
        userId: number
    }

    interface ComponentModel {
        isLoading: boolean,
        searchText: string | null,
        passwordInputType: string,
        login: string,
        password: string,
        loginErrorText: string,
        passwordErrorText: string,
        isLoginButtonDisable: boolean,
        errors: string[],
        successMessages: string[],
        menu: MenuWrapper[],
        isShowMenu: boolean,
        unreadChatMessageCount: number,
        isChatConnectionRetry: boolean,
        hubConnection: any,
        isChatStoppedBySystem: boolean,
        isThrottled: boolean,
        isNeedToUpdateUnreadChatMessages: boolean,
        availableClubs: SelectModel[]
        availableClubsForCalls: SelectModel[]
        selectedClub: SelectModel | null,
        selectedClubForCalls: SelectModel | null,
        isAcceptCalls: boolean,
        isEnLanguageSelect: boolean,
        isOptionsOpen: boolean,
        isUpdating: boolean,
        callInterval: any,
        hasConnection: boolean,
        isShowFindCard: boolean,
        abortRequest: AbortController | null,
        networkLogo: string | null,
        clubs: Club[]
    }

    export default defineComponent({
        name: 'App',
        data(): ComponentModel {
            return {
                isLoading: true,
                searchText: "",
                passwordInputType: "password",
                login: "",
                password: "",
                loginErrorText: "",
                passwordErrorText: "",
                isLoginButtonDisable: false,
                errors: [], 
                successMessages: [], 
                menu: [],
                isShowMenu: false,
                unreadChatMessageCount: 0,
                isChatConnectionRetry: false,
                hubConnection: undefined,
                isChatStoppedBySystem: false,
                isThrottled: false,
                isNeedToUpdateUnreadChatMessages: false,
                availableClubs: [],
                availableClubsForCalls: [],
                selectedClub: null,
                selectedClubForCalls: null,
                isAcceptCalls: false,
                isEnLanguageSelect: false,
                isOptionsOpen: false,
                isUpdating: false,
                callInterval: null,
                hasConnection: false,
                isShowFindCard: false,
                abortRequest: null,
                networkLogo: null,
                clubs: []
            }
        },
        methods: {
            switchLanguage: async function () {
                await switchLanguage(getCurrentLocal() === "ru" ? "en" : "ru");

                this.isUpdating = true;

                nextTick(() => { this.isUpdating = false; });
            },
            changeType: function () {
                if (this.passwordInputType == "password") this.passwordInputType = "text";
                else this.passwordInputType = "password"

            },
            search: function () {
                if (!this.searchText) return;
                this.$router.push('/search?find=' + this.searchText);
                this.searchText = null;
            },
            signIn: async function () {
                if (!this.login) this.loginErrorText = 'main.login_placeholder';
                if (!this.password) this.passwordErrorText = 'main.password_placeholder';

                if (!this.login || !this.password) return;

                this.isLoginButtonDisable = true;
                this.isUpdating = true;
                await axiosRequests.post(process.env.VUE_APP_AUTH_URL, { login: this.login, password: this.password })
                    .then(
                        async (data: Token) => {
                            this.cookies.set("accessToken", data.accessToken, data.accessTokenLifetime + "s", "/", undefined, true);
                            this.cookies.set("refreshToken", data.refreshToken, data.refreshTokenLifetime + "s", "/", undefined, true);

                            this.isShowMenu = window.innerWidth > 1565;

                            await this.getCurrentUser();
                            await this.getMenu();
                            await this.getAvailableClubs();


                            this.$store.commit('SET_ISAUTH', true);

                            this.connectToChat();

                            this.password = "";
                            this.login = "";
                            this.passwordInputType = "password";
                            this.isLoginButtonDisable = false;
                            this.loginErrorText = "";
                            this.passwordErrorText = "";
                             
                            if(this.$route.path != "/forbidden"){
                                 nextTick(() => { this.isUpdating = false; });
                            } else {
                                this.$router.push('/clients');
                                setTimeout(() => {  this.isUpdating = false; }, 300);
                            }
                           
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoginButtonDisable = false;
                            this.isUpdating = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                );
            },
            updateUnreadChatMessages: async function () {
                if (this.isThrottled) {
                    this.isNeedToUpdateUnreadChatMessages = true;
                    return;
                }

                this.isThrottled = true;

                await this.getUnreadChatMessagesCount();

                setTimeout(async () => {
                    if (this.isNeedToUpdateUnreadChatMessages) {
                        await this.getUnreadChatMessagesCount();
                        this.isNeedToUpdateUnreadChatMessages = false;
                    }

                    this.isThrottled = false;
                }, 1000);
            },
            checkCanIfAccessTokenValid: async function (): Promise<boolean> {
                this.isChatConnectionRetry = true;
                const token = this.cookies.get("accessToken");

                if (token) return true;

                const refreshToken = this.cookies.get("refreshToken");

                if (!refreshToken) return false;

                try {
                    const result: Token = await axiosRequests.post(process.env.VUE_APP_REFRESH_URL, { refreshToken: refreshToken });

                    this.cookies.set("accessToken", result.accessToken, result.accessTokenLifetime + "s", "/", undefined, true);
                    this.cookies.set("refreshToken", result.refreshToken, result.refreshTokenLifetime + "s", "/", undefined, true);

                    return true;
                } catch {
                    return false;
                } 
            },
            connectToChat: async function () {
                try {
                    const token = this.cookies.get("accessToken");

                    this.isChatStoppedBySystem = true;
                    this.$store.commit('SET_HUBCONNECTION', undefined);
                    this.hubConnection?.stop();

                    this.hubConnection = new signalR.HubConnectionBuilder()
                        .withUrl(`${process.env.VUE_APP_CHAT_URL}chathub`, { accessTokenFactory: () => token })
                        .withAutomaticReconnect()
                        .build();

                    await this.hubConnection.start()

                    this.hubConnection.invoke("EnterAdministrator")

                    this.hubConnection.on("UpdateUnreadMessagesCount", () => this.updateUnreadChatMessages())

                    nextTick(() => {
                        this.$store.commit('SET_HUBCONNECTION', this.hubConnection);

                        this.$store.commit('SET_IS_CHAT_RECONNECTING', false);
                        this.$store.commit('SET_IS_CHAT_CONNECTED', true);
                        this.isChatStoppedBySystem = false;
                    });
                   
                    this.isChatConnectionRetry = false;

                    this.getUnreadChatMessagesCount();

                    this.hubConnection.onreconnecting(async () => {
                        this.$store.commit('SET_IS_CHAT_RECONNECTING', true);
                    })

                    this.hubConnection.onreconnected(async () => {
                        await this.connectToChat();
                    })

                    this.hubConnection.onclose(async () => {
                        if (this.isChatStoppedBySystem) return;

                        if (this.isChatConnectionRetry) {
                            this.$store.commit('SET_ADD_ERROR', 'error.chat_connection_lost');
                            this.$store.commit('SET_IS_CHAT_RECONNECTING', false);
                            this.$store.commit('SET_IS_CHAT_CONNECTED', false);
                            return;
                        }

                        if (!await this.checkCanIfAccessTokenValid()) {
                            this.$store.commit('SET_ADD_ERROR', 'error.chat_connection_lost');
                            this.$store.commit('SET_IS_CHAT_RECONNECTING', false);
                            this.$store.commit('SET_IS_CHAT_CONNECTED', false);
                            return;
                        }

                        await this.connectToChat();
                    })

                }
                catch (e: any) {
                    if (this.isChatStoppedBySystem) return;

                    if (this.isChatConnectionRetry) {
                        this.$store.commit('SET_ADD_ERROR', 'error.chat_connection_lost');
                        this.$store.commit('SET_IS_CHAT_RECONNECTING', false);
                        this.$store.commit('SET_IS_CHAT_CONNECTED', false);
                        return;
                    }

                    if (!await this.checkCanIfAccessTokenValid()) {
                        this.$store.commit('SET_ADD_ERROR', 'error.chat_connection_lost');
                        this.$store.commit('SET_IS_CHAT_RECONNECTING', false);
                        this.$store.commit('SET_IS_CHAT_CONNECTED', false);
                        return;
                    }

                    await this.connectToChat();
                }
            },
            getCurrentUser: async function () {
                await axiosRequests.get(editUrl(Routes.getCurrentUser))
                    .then(
                        (data: User) => {
                            this.$store.commit('SET_CURRENT_USER', data);
                            this.$store.commit('SET_ISAUTH', true);

                            let networkId = data.networkId;
                            if (data.mainRoleId == 1) networkId = 0;

                            let clubId = data.clubId;

                            if (data.mainRoleId == 1 || data.mainRoleId == 2 || data.mainRoleId == 3){
                                clubId = parseInt(localStorage.getItem("selected-club") ?? "0");
                            }

                            this.$store.commit('SET_SELECTED_CLUB_ID', clubId);
                            this.$store.commit('SET_NETWORK_ID', networkId);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getAvailableClubs: async function () {
                this.availableClubs = [];
                this.selectedClub = null;

                await axiosRequests.get(editUrl(Routes.getClubs))
                    .then(
                        (data: Club[]) => {
                            this.clubs = data;

                            this.availableClubs = data.map(x => { return { id: x.id.toString(), text: x.name, isTranslated: false, disabled: false, groupName: null } });
                            this.availableClubsForCalls = data.map(x => { return { id: x.id.toString(), text: x.name, isTranslated: false, disabled: false, groupName: null } });

                            if (this.availableClubs.length > 1) {
                                this.availableClubs.unshift({ id: "0", text: 'main.all_clubs', isTranslated: true, disabled: false, groupName: null });
                            }

                            if (this.availableClubs.length == 1) {
                                this.selectedClub = this.availableClubs[0];
                            } else {
                                this.selectedClub = this.availableClubs.find(x => x.id == this.$store.getters.getSelectedClubId) ?? null;
                            }

                            if (this.selectedClub != null) {
                                this.networkLogo = data.find(x => x.id.toString() == this.selectedClub?.id)?.networkLogo ?? null;
                            }

                            let selectedClubForCalls = localStorage.getItem("selected-club-for-calls");

                            if (selectedClubForCalls) {
                                this.selectedClubForCalls = this.availableClubsForCalls.find(x => x.id == selectedClubForCalls) ?? null;
                            } else {
                                if (this.availableClubsForCalls.length > 0) this.selectedClubForCalls = this.availableClubsForCalls[0];
                            }
                            
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getMenu: async function () {
                await axiosRequests.get(editUrl(Routes.getMenu))
                    .then(
                        (data: Menu[]) => {
                            this.menu = [];
                            for (let i = 0; i < data.length; i++) {
                                this.menu.push({
                                    icon: data[i].icon,
                                    text: data[i].text,
                                    url: data[i].url,
                                    subItems: data[i].subItems,
                                    hasSubItems: data[i].subItems?.length > 0,
                                    isOpen: this.activeMenuItem == data[i].url
                                })
                            }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getUnreadChatMessagesCount: async function () {
                let clubs = this.$store.getters.getSelectedClubId;
                if (!clubs || clubs == "0") clubs = this.availableClubs.map(x => x.id).toString();

                await axiosRequests.get(`${process.env.VUE_APP_CHAT_URL}Chat/GetUnreadMessagesCount?clubs=${clubs}`)
                    .then(
                        (data: number) => {
                            this.unreadChatMessageCount = data;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            closeError: function (error) {
                let index = this.errors.findIndex(x => x == error);
                if (index != -1) this.errors.splice(index, 1);
                this.$store.commit('SET_REMOVE_ERROR', error);
            },
            closeSuccessMessages: function (successMessages) {
                let index = this.successMessages.findIndex(x => x == successMessages);
                if (index != -1) this.successMessages.splice(index, 1);
                this.$store.commit('SET_REMOVE_SUCCESS_MESSAGES', successMessages);
            },
            clickOnMenuItem(item: MenuWrapper) {
                if (!item || !item.url) {
                    console.log("Ошибка с переходом на страницу");
                    console.log(`${item.text.toString()} | ${item.url.toString()}`);
                    for (let i = 0; i < this.menu.length; i++)  console.log(`${this.menu[i].text.toString()} | ${this.menu[i].url.toString()}`);
                    return;
                }

                if (item.hasSubItems) {
                    item.isOpen = !item.isOpen;
                } else {
                    if(window.innerWidth <= 1565) this.isShowMenu = false;
                    this.$router.push(item.url);
                }
            },
            clickOutsideMenu: function (e: any) {
                if (!e.target.classList || e.target.classList?.contains('hamburger_button')) return;
                if (window.innerWidth <= 1565) this.isShowMenu = false;
            },
            signOut: function () {
                this.isShowMenu = false;
                this.$store.commit('SET_ISAUTH', false);
                this.$store.commit('SET_CURRENT_USER', null);

                this.cookies.remove("accessToken");
                this.cookies.remove("refreshToken");

                this.$store.commit('SET_SELECTED_CLUB_ID', "0");
                localStorage.removeItem("selected-club");
                localStorage.removeItem("selected-club-for-calls");

                this.$store.commit('SET_CLIENTS_PERMISSION', []);
                this.$store.commit('SET_CONTRACTS_PERMISSION', []);
                this.$store.commit('SET_SERVICE_CONTRACTS_PERMISSION', []);
                this.$store.commit('SET_ORDERS_PERMISSION', []);
                this.$store.commit('SET_TRAINING_BLOCKS_PERMISSION', []);
                this.$store.commit('SET_USER_PERMISSION', []);
                this.$store.commit('SET_REFUND_ORDERS_PERMISSION', []);
                this.$store.commit('SET_MAILINGS_PERMISSION', []);
                this.$store.commit('SET_TASKS_PERMISSION', []);
                this.$store.commit('SET_TIMETABLES_PERMISSION', []);

                this.networkLogo = null;
                this.isChatStoppedBySystem = true;
                this.hubConnection.stop();
            },
            selectClub: function () {
                this.$store.commit('SET_SELECTED_CLUB_ID', this.selectedClub?.id ?? "0");

                localStorage.setItem("selected-club", this.selectedClub?.id ?? "0");

                if (this.selectedClub != null) {
                    this.networkLogo = this.clubs.find(x => x.id.toString() == this.selectedClub?.id)?.networkLogo ?? null;
                } else {
                    this.networkLogo = null;
                }

                this.isUpdating = true;

                nextTick(() => { this.isUpdating = false; });
            },
            selectAcceptCallsClub: function () {
                if (!this.selectedClubForCalls) return;

                localStorage.setItem("selected-club-for-calls", this.selectedClubForCalls.id);

                if (this.isAcceptCalls) {
                    clearInterval(this.callInterval);
                    this.callInterval = setInterval(() => this.checkIfCalling(), 10000);
                }
            },
            clickOnOptionsButton: function (event: any) {
                this.isOptionsOpen = !this.isOptionsOpen;

                if (window.innerWidth <= 768) this.isShowMenu = false;

                if (!this.isOptionsOpen) return;

                nextTick(() => {
                    let options: any = this.$refs.options;
                    let element = event.target;
                    if (element.classList.contains("setting_button_img")) element = element.parentElement;

                    options.style.left = `${element.getBoundingClientRect().right - options.getBoundingClientRect().width}px`;
                    options.style.top = `${element.getBoundingClientRect().bottom + 3 }px`;
                });
               
            },
            onClickOutsideOptions: function (event: any) {
                if (event.target.classList?.contains("setting_button") || event.target.classList?.contains("setting_button_img") || event.target.classList?.contains("setting_button_mobile")) return;
                this.isOptionsOpen = false;
            },
            resizeOptionWindow: function () {
                let height = 0;

                nextTick(() => {
                    let select_component = this.findElement((this.$refs.acceptCallClubSelect as any).children[0], "select_component");

                    if (select_component == null) return;

                    for (let element of select_component.children) {
                        height += element.getBoundingClientRect().height;
                    }

                    const options: any = this.$refs.options;

                    for (let element of options.children) {
                         if (element.classList.contains("accept_calls_club_select")) continue;

                         height += element.getBoundingClientRect().height;
                     }

                     height += 10;

                    options.style.minHeight = `${height}px`;
                })              
            },
            findElement: function (element: any, className: string) {
                if (element.classList.contains(className)) return element;

                if (element.parentElement) return this.findElement(element.parentElement, className);

                return null;
            },
            clickOnAcceptCalls: function () {
                const options: any = this.$refs.options;
                options.style.minHeight = `0px`;

                this.updateInterval();
            },
            updateInterval: function () {
                if (this.isAcceptCalls) {
                    localStorage.setItem("accept-calls", "true");

                    this.callInterval = setInterval(() => this.checkIfCalling(), 15000);
                } else {
                    localStorage.removeItem("accept-calls");
                    clearInterval(this.callInterval);
                    this.$store.commit('SET_LEAD_CALLING', null);
                }  
            },
            checkIfCalling: function () {
                if(!this.selectedClubForCalls) return;

                 axiosRequests.get(editUrl(Routes.checkIfCalling, this.selectedClubForCalls.id))
                    .then(
                        (data: LeadCalling) => {
                            this.$store.commit('SET_LEAD_CALLING', data);
                        }
                    )
                    .catch(
                        (err: any) => {
                            if (err.status == 401)  return;
                        }
                    );
            },
            clickOnCallingButton: function () {
                if (this.activeMenuItem == "/tasks") return;

                this.$store.commit('SET_IS_CALLING_BUTTON_CLICKED', true);
                this.$router.push('/tasks');
            },
            findCardOwner: function () {
                this.isShowFindCard = true;

                this.abortRequest = new AbortController();

                axiosRequests.get(editUrl(Routes.findPassCardLocal, undefined, true), this.abortRequest.signal)
                    .then(
                        (data: FindPassCardModel) => {
                            this.isShowFindCard = false;

                            if (data.clientId) {
                                this.$store.commit('SET_LAST_VIEWED_CLIENT', { fi: "", id: data.clientId, buttonText: 'back_button.back', buttonPath: "-1", isNeedTranslatedButtonText: true });

                                this.$router.push(`/client/${data.clientId}`);
                                return;
                            }

                            if (data.userId) {
                                this.$router.push(`/user/${data.userId}`);
                                return;
                            }
                        }
                    )
                    .catch(
                        (err: any) => {
                            this.isShowFindCard = false;

                            if (!err?.status) return;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                               
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            closeFindCard: function () {
                this.isShowFindCard = false;
                this.abortRequest?.abort();

                axiosRequests.post(editUrl(Routes.cancelCurrentOperation, undefined, true)).then(() => { }).catch(() => { })
            },
            checkConnection: function () {
                axiosRequests.get(editUrl(Routes.checkConnection, undefined, true))
                    .then(() => { this.hasConnection = true; this.$store.commit('SET_HAS_CONNECTION_TO_LOCAL', true) })
                    .catch(() => { this.$store.commit('SET_HAS_CONNECTION_TO_LOCAL', false) });
            }
        },
        async created() {
            const local = localStorage.getItem("user-locale");

            if (local !== getCurrentLocal() && local != 'null') await switchLanguage(local);

            this.isEnLanguageSelect = getCurrentLocal() !== "ru";
        },
        setup() {
            const { cookies } = useCookies();
            return { cookies };
        },
        watch: {
            'errorsCount': function () {
                let errors = this.$store.getters.getErrors;
                for (let i = 0; i < errors.length; i++) {
                    if (!this.errors.includes(errors[i])) {
                        this.errors.push(errors[i]);
                        setTimeout(this.closeError, 5000, errors[i]);
                    }
                }
            },
            'successMessagesCount': function () {
                let successMessages = this.$store.getters.getSuccessMessages;
                for (let i = 0; i < successMessages.length; i++) {
                    if (!this.successMessages.includes(successMessages[i])) {
                        this.successMessages.push(successMessages[i]);
                        setTimeout(this.closeSuccessMessages, 5000, successMessages[i]);
                    }
                }
            },
            'isAuth': function () {
                if (!this.isAuth) this.hubConnection?.stop();
            }
        },
        computed: {
            activeMenuItem: function () {
                return getUrlPlural(this.$store.getters.getActiveMenu);
            },
            activeSubmenuItem: function () {
                return getUrlPlural(this.$store.getters.getActiveSubmenu);
            },
            errorsCount: function () {
                return this.$store.getters.getErrors.length;
            },
            successMessagesCount: function () {
                return this.$store.getters.getSuccessMessages.length;
            },
            currentUser: function () {
                return this.$store.getters.getCurrentUser;
            },
            load: function () {
                return this.$store.getters.getLoad;
            },
            isAuth: function () {
                return this.$store.getters.getIsAuth;
            },
            isShowInternalServerError: function () {
               return this.$store.getters.getIsShowInternalServerError;
            },
            leadCalling: function () {
                return this.$store.getters.getLeadCalling;
            },
            isLeadOpen: function () {
               return this.$store.getters.getIsLeadOpen;
            }
        },
        components: { page500: ViewPage500, passCardAlert: PassCardAlert },
        async beforeMount() {
            this.checkConnection();
            await this.getCurrentUser();

            if (this.isAuth) {
                this.isShowMenu = window.innerWidth > 1565;

                await this.getMenu();
                await this.getAvailableClubs();
                this.connectToChat();

                this.isAcceptCalls = localStorage.getItem("accept-calls") == "true";

                this.updateInterval();
            }

            const lastViewedClients = localStorage.getItem("last-viewed-clients");

            if(lastViewedClients){
                this.$store.commit('SET_LAST_VIEWED_CLIENTS', JSON.parse(lastViewedClients));
            }
            
            this.isLoading = false;
        }
    });
</script>

<style scoped>
    .header {
        background-color: white;
        padding: 12px 24px 12px 24px;
        display: flex;
        justify-content: space-between;
    }

    .setting_button {
        cursor: pointer;
        height: 36px;
        width: 36px;
        min-width: 36px;
        border: none;
        background-color: #f2f2f2;
        border-radius: 8px;
        margin-left: 15px;
    }

    .setting_button img {
        height: 18px;
        margin-top: 4px;
    }

    .user_wrapper{
        display: flex;
    }

    .user_name {
        margin: 0px;
        display: flex;
        flex-flow: row-reverse;
    }

    .user_role {
        color: #65656F;
        font-size: 12px;
        margin: 0;
        display: flex;
        flex-flow: row-reverse;
    }

    .user_photo {
        background-size: cover;
        width: 31px;
        height: 31px;
        margin: auto;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
    }

    .search_wrapper {
        display: flex;
        flex: auto;
        max-width: 69%;
        margin-inline: 20px;
    }

    .hamburger_button {
        cursor: pointer;
        height: 32px;
        margin-right: 25px;
    }

    .header_title {
        margin: 3px 0px 3px 17px;
        font-size: 20px;
        font-weight: bold;
    }

    .setting_button_mobile{
        display: none;
    }

    .logo_wrapper{
        display: flex;
    }

    .auth_wrapper {
        width: 375px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
    }

    .auth_button {
        width: 100%;
        background-color: #21A7D1;
        border-radius: 15px;
        border: none;
        margin-top: 24px;
        color: white;
        height: 44px;
        font-size: 18px;
        cursor: pointer;
    }

    .auth_text {
        line-height: 24px;
    }

    .body {
        height: 100vh;
    }

    .even_messages_wrapper {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9999999999
    }

    .error {
        position: relative;
        background-color: #FBE0E0;
        color: #E22E2E;
        border-left: 4px solid #E22E2E;
        border-radius: 4px;
        display: flex;
        width: 360px;
        padding: 16px 16px 16px 20px;
        gap: 4px;
        margin: 0px 0px 10px 0px;
        animation: showEventMessage 6s linear;
        z-index: 9999999999
    }

    .error_close {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 10px;
        cursor: pointer;
    }

    .success {
        position: relative;
        background-color: #b8ffb8;
        color: #008000;
        border-left: 4px solid #008000;
        border-radius: 4px;
        display: flex;
        width: 360px;
        padding: 16px 16px 16px 20px;
        gap: 4px;
        margin: 0px 0px 10px 0px;
        animation: showEventMessage 6s linear;
        z-index: 9999999999
    }

    .success_close {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 10px;
        cursor: pointer;
    }

    .menu_wrapper {
        display: flex;
        flex-flow: column;
        border-radius: 0px 24px 24px 0px;
        background-color: white;
        padding: 24px;
        width: 230px;
        height: max-content;
        min-width: 230px;
        position: fixed;
        z-index: 3;
    }

    .menu_item_wrapper{
        text-decoration: none
    }

    .menu_item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #101111;
        margin-bottom: 24px;
        cursor: pointer;
        position: relative;
    }

    .menu_active {
        filter: invert(60%) sepia(85%) saturate(1444%) hue-rotate(157deg) brightness(87%) contrast(86%);
    }

    .menu_item_arrow {
        position: absolute;
        right: 0px;
        height: 20px;
    }

    .hr {
        margin: 0px;
        background-color: #D5D5D5;
        height: 1px;
    }

    .menu_mobile_search{
        display: none;
    }

    .close_button {
        height: 24px;
        display: none;
    }

    .body_content {
        padding-inline: 16px;
        width: calc(100% - 32px);
        max-width: calc(100vw - 310px)
    }

    .unread_messages_count {
        background-color: #F7C9E4;
        border-radius: 12px;
        padding: 3px 10px 4px 10px;
        margin-left: 12px;
        font-size: 13px;
        color: #AB0064;
        height: 14px;
        display: flex;
        margin-top: 0px;
        position: absolute;
        right: 0px;
    }

    .unread_messages_count p {
        width: 17px;
        text-align: center;
    }

    .options_wrapper {
        position: absolute;
        right: 0;
        display: flex;
        flex-flow: column;
        background-color: white;
        width: -moz-max-content;
        width: max-content;
        border: 1px solid #D5D5D5;
        border-radius: 12px;
        top: 37px;
        overflow: hidden;
        min-width: 270px;
        z-index: 4;
        padding: 18px;
        box-shadow: 1px 3px 10px #d5d5d5;
    }

    .switcher {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        position: relative;
        vertical-align: middle;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
    }

    .switcher_switch {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        width: 40px;
        height: 20px;
        border: 1px solid #21A7D1;
        vertical-align: top;
        background: #C7E9F3;
        transition: .2s;
        border-radius: 18px;
    }

    .switcher_switch:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #21A7D1;
        transition: .15s;
    }

    .switcher input[type=checkbox]:checked + .switcher_switch:before {
        transform: translateX(19px);
    }

    .calling_wrapper {
        background: rgb(160, 42, 123);
        display: flex;
        position: fixed;
        width: 90px;
        height: 90px;
        z-index: 10;
        top: 65px;
        right: 55px;
        border-radius: 50%;
        transform: scale(1);
        cursor: pointer;
        animation: pulse 2s infinite;
    }

    .current_club_wrapper {
        min-width: 350px;
        margin-left: 15px;
        height: 35px;
    }

    .menu_wrapper_hide {
        display: none;
        animation: hideMenu 0.2s linear;
    }

    .menu_wrapper_show {
        display: flex;
        box-shadow: 0px 0px 10px #d5d5d5;
        animation: showMenu 0.2s linear;
    }

    .body_content_with_menu {
        margin-left: 280px;
        animation: moveLeftBoby 0.2s linear;
    }

    .body_content_without_menu {
        max-width: calc(100vw - 32px);
        animation: moveRightBoby 0.2s linear;
    }

    .img_wrapper {
        width: 130px;
        overflow: hidden;
    }

    .network_logo {
        height: 36px;
        width: auto;
    }

    @media(max-width: 1565px) {
        .body_content {
            max-width: calc(100vw - 32px)
        }

        .body_content_with_menu {
            margin-left: 0px;
            animation: unset;
        }

        .body_content_without_menu {
            max-width: calc(100vw - 32px);
            animation: unset;
        }
    }


    @media(max-width: 1200px) {
        .user_wrapper {
            display: none;
        }

        .search_wrapper {
            max-width: 80%;
            margin-inline: 0px;
            margin-left: 20px;
        }
    }
    
    @media(max-width: 960px) {
        .current_club_wrapper {
            min-width: 250px;
        }
    }

    @media(max-width: 768px) {
        .search_wrapper {
            display: none;
        }

        .setting_button_mobile {
            display: block;
        }

        .logo_wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        .hamburger_button {
            height: 24px;
            margin: 0px;
        }

        .setting_button_mobile {
            height: 21px;
            margin-right: 12px;
            margin-top: 1px;
        }

        .logo_img {
            height: 24px;
        }

        .network_logo {
            height: 25px;
        }

        .logo_buttons_wrapper {
            order: 1;
        }

        .header_title {
            margin: 0px 0px 0px 17px;
        }

        .menu_wrapper {
            border-radius: 0px 0px 24px 24px;
            position: absolute;
            left: 0;
            right: 0;
            top: 48px;
            width: auto;
            padding-top: 0px;
            max-height: calc(100vh - 67px);
            overflow: auto;
        }

        .menu_mobile_search {
            display: block;
        }

        .menu_mobile_wrapper_hide {
            display: none;
            animation: hideMenuFromTop 0.2s normal;
        }

        .menu_mobile_wrapper_show {
            display: flex;
            box-shadow: 0px 7px 5px #d5d5d5;
            animation: showMenuFromTop 1s normal;
        }

        .mobile_hide{
            display: none;
        }

        .mobile_show {
            display: block;
        }
    }

    @media(max-width: 500px) {
        .auth_wrapper {
            width: auto;
            position: static;
            transform: unset;
            margin-top: 8px;
        }

        .error {
            width: calc(100vw - 40px);
        }

        .options_wrapper {
            border-radius: 0px 0px 24px 24px;
            position: absolute;
            left: -1px !important;
            right: -1px;
            top: 48px !important;
            width: auto;
            padding-top: 18px;
            max-height: calc(100vh - 67px);
            overflow: auto;
        }

        .options_mobile_wrapper_show {
            display: flex;
            box-shadow: 0px 7px 5px #d5d5d5;
            animation: showMenuFromTop 1s normal;
        }

        .options_mobile_wrapper_hide {
            display: none;
            animation: hideMenuFromTop 1s normal;
        }
    }

    .load_bar_wrapper {
        position: absolute;
        height: 3px;
        display: block;
        width: 100%;
        background-color: white;
        overflow: hidden;
        top: 0;
    }

    .load_bar {
        background-color: #21A7D1;
    }

    .load_bar::before {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        animation: indeterminate 3.5s infinite;
    }

    @keyframes pulse {

        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(160, 42, 123, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 30px rgba(160, 42, 123, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(160, 42, 123, 0);
        }
    }


    @keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        100% {
            left: 100%;
            right: -200%;
        }
    }

    @keyframes showEventMessage {
        0% {
            left: 405px;
        }

        5% {
            left: 0px;
        }

        79% {
            left: 0px;
        }

        84% {
            left: 405px;
        }
        100% {
            display: none;
        }
    }

    @keyframes showMenu {
        0% {
            left: -250px;
        }

        100% {
            left: 0;
        }
    }

    @keyframes hideMenu {
        0% {
            display: flex;
            left: 0px;
        }

        100% {
            display: flex;
            left: -350px;
        }
    }

    @keyframes moveLeftBoby{
        0% {
            margin-left: 0px;
            max-width: calc(100vw - 32px);
        }

        100% {
            margin-left: 280px;
            max-width: calc(100vw - 310px);
        }
    }

    @keyframes moveRightBoby {
        0% {
            margin-left: 280px;
        }

        99% {
            margin-left: 0px;
        }

        100% {
            max-width: calc(100vw - 32px);
        }
    }

    @keyframes showMenuFromTop {
        0% {
           max-height: 0;
           overflow: hidden;
        }

        100% {
            overflow: hidden;
            max-height: 5000px;
        }
    }

    @keyframes hideMenuFromTop {
        0% {
            display: flex;
            max-height: 100vh;
            overflow: hidden;
        }

        100% {
            display: flex;
            overflow: hidden;
            max-height: 0;
        }
    }
    
    
</style>
