export const Routes = {
    getCurrentUser: 'users/current',

    getMenu: 'menu/items',
    getMembersTabs: 'menu/members/tabs',

    getPermission: 'permissions/{id}',

    getClients: 'clients',
    getActiveClients: 'clients/active',
    getClientsInClub: 'clients/in-club',
    getInDebtClients: 'clients/in-debt',
    getEndSoonClients: 'clients/end-soon',
    getFormerClients: 'clients/former',
    getCorporateClients: 'clients/corporate-contracts',
    getNewClients: 'clients/new-contracts',
    getClientsByIds: 'clients/by-ids',

    getClient: 'clients/{id}/detailed',
    createClient: 'clients/create',
    updateClient: 'clients/{id}/update',
    resetClientPassword: 'clients/{id}/reset-password',
    changeBankCard : 'clients/{id}/change-bank-card',
    sendMessageToClient : 'clients/{id}/send-message',
    createClientLead : 'clients/{id}/create-lead',
    uploadClientPhoto: 'clients/{id}/upload-photo',
    registerPass: 'clients/{id}/register-pass',

    addRestrictionsToClient: 'clients/{id}/restrictions/create',
    deleteRestriction: 'clients/restrictions/{id}/delete',

    getClientSources: 'clients/{id}/sources',
    updateClientSources: 'clients/{id}/sources/update',

    getOrders: 'orders/by-client-id/{id}',
    getOrder: 'orders/{id}/detailed',
    getOrderStatus: 'orders/{id}/status',

    refundOrder: 'orders/{id}/refund',
    cancelOrder: 'orders/{id}/cancel',
    resumeOrder: 'orders/{id}/resume',
    payForOrder: 'orders/{id}/pay',

    getClientTrainings: 'trainings/by-client-id/{id}',
    getClientsTrainingBlocks: 'training-blocks/by-client-id/{id}',
    getClientEvents: 'client-events/by-client-id/{id}',
    getReferralMembers: 'referral-members/by-client-id/{id}',
    getClientAttendances: 'attendances/by-client-id/{id}',

    getRefundOrders: 'refund-orders/by-client-id/{id}',
    getRefundOrder: 'refund-orders/{id}/detailed',

    createComment: 'clients/{id}/comments/create',
    deleteComment: 'clients/comments/{id}/delete',

    getClubs: "clubs",
    getClubsWithAvailableAgreements: "clubs/agreements",

    getContract: 'contracts/{id}/detailed',
    changeContractStatus: 'contracts/{id}/change-status',
    cancelPlannedFreeze : 'contracts/{id}/cancel-planned-freeze',
    cancelTermination : 'contracts/{id}/cancel-termination',
    createContract: 'contracts/create',

    findClients: 'clients/find',

    getAvailableClubTrainingBlocks : 'training-blocks/club-trainings/available-by-client-id/{id}',
    getAvailableTrainerTrainingBlocks: 'training-blocks/trainer-trainings/available-by-client-id/{id}',

    buyClubTrainingBlock : 'training-blocks/club/buy',
    buyTrainerTrainingBlock: 'training-blocks/trainer/buy',

    getUser: 'users/{id}',
    getUsers: 'users',

    createServiceContract: 'service-contracts/create',
    retryToPayServiceContract: 'service-contracts/{id}/retry-to-pay',
    retryToPayAdditionalFreezingDaysContract: 'contract-freezing-days-additionals/{id}/retry-to-pay',
    retryToPayTrainingBlockContract: 'training-blocks/{id}/retry-to-pay',
    cancelServiceContract: 'service-contracts/{id}/cancel',

    getOrganizations: "organizations/by-club-id/{id}",

    checkIfCalling: "clubs/{id}/check-if-phone-is-calling",

    getMailings: 'mailings',
    getMailing: 'mailings/{id}',
    createMailing: 'mailings/create',
    updateMailing: 'mailings/{id}/update',
    publishMailing: 'mailings/{id}/publish',
    stopMailing: 'mailings/{id}/stop',
    resumeMailing: 'mailings/{id}/resume',

    getMailingGroups: 'mailings/groups',

    getAllLeads:'leads',
    getLead:'leads/{id}',
    getAllLeadsStatuses:'leads/statuses',
    getAllManagers:'leads/managers',
    changeLeadDate:'leads/{id}/change-next-step-date',
    findLead:'leads/find',
    createLead:'leads/create',
    updateLead:'leads/{id}/update',

    getTimetableForGroupTrainings: "timetables/group-trainings",
    getTimetableForPersonalTrainings: "timetables/personal-trainings",

    getTimetableFilters: "timetables/filters",
    getTraining: "timetables/trainings/{id}",

    findGroupTrainingClients : "timetables/group-trainings/clients/find",
    findPersonalTrainingClients: "timetables/personal-trainings/clients/find",

    createGroupTraining: "timetables/group-trainings/create",
    createPersonalTraining: "timetables/personal-trainings/create",

    registerClientOnGroupTraining: "timetables/group-trainings/register-client",
    addToWaitingList: "timetables/waiting-list/add-client",

    updateGroupTraining: "timetables/group-trainings/{id}/update",
    deleteGroupTraining: "timetables/group-trainings/{id}/delete",

    retryToPayTraining: "timetables/trainings/retry-to-pay",

    updatePersonalTraining: "timetables/personal-trainings/{id}/update",
    deletePersonalTraining: "timetables/personal-trainings/{id}/delete",

    copyGroupTrainingToNextWeek: "timetables/group-trainings/copy-to-next-week",
    downloadGroupTrainings: "timetables/group-trainings/download-printable",

    uploadUserPhoto: 'users/{id}/upload-photo',

    downloadActiveClients : "clients/active/download",
    downloadAllClients: "clients/download",
    downloadClientsInClub: "clients/in-club/download",
    downloadClientsWithNewContracts: "clients/new-contracts/download",
    downloadClientsInDebt: "clients/in-debt/download",
    downloadClientsWithContractEndSoon: "clients/end-soon/download",
    downloadFormerClients: "clients/former/download",
    downloadClientsWithCorporateContracts: "clients/corporate-contracts/download",

    getAvailableClubsForAddingPassCard: "pass-cards/available-clubs",
    getAvailableClubsForAddingRestriction: "pass-cards/available-clubs",

    checkConnection : "check-connection",
    findPassCardLocal: "pass-cards/find",
    createPassCardLocal: "pass-cards/create",
    deactivatePassCardLocal: "pass-cards/deactivate",
    cancelCurrentOperation: "pass-cards/cancel-current-operation",
}